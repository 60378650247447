import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OEHttpClient } from '../core';
import { IAstroBatchesResults, IAstroHoldingRestriction, IAstroMessageResponse, IAstroOptimizeRequest, IAstroBatchOptimization, IAstroBatchStatus } from '../models/astro';
import { ISecurityPreference } from '../models/preferences/securityPreference';

@Injectable({
  providedIn: 'root'
})
export class AstroService {

  private _optimizationBatchEndPoint = 'v2/Optimization/Batch';
  private _optimizationBatchStatusEndPoint = 'v2/Optimization/Status/Batch';
  private _optimizationSummaryBatchEndPoint = 'v2/Optimization/Summary/Batch';
  private _optimizationSummaryAccountEndPoint = 'v2/Optimization/Summary/Account';
  private _optimizationHoldingSummaryAccountEndPoint = 'v2/Optimization/HoldingSummary/Account';
  private _optimizationTaxLossHarvesting = 'v2/Optimization/TaxLossHarvesting';
  private _tradeAstroBatchBulkEndPoint = 'v2/TradeOrder/Trades/Astro/Batch/Bulk';
  private _astroTradeImportEndPoint = 'v2/TradeOrder/Trades/Astro/Import';
  private _astroTradeImportSymmaryEndPoint = 'v2/TradeOrder/Trades/Astro/Import/Summary';
  private _astroModelEndPoint = 'v2/Modeling/Models/Astro';
  private _astroAccountsEndPoint = 'v2/Account/AstroAccounts';
  private _astroAccountFiltersEndPoint = 'v2/Account/AstroAccounts/Filters';
  private _astroUpdateAccountFieldsEndPoint = 'v2/Account/AstroAccounts/UpdateAstroFields';
  private _astroTemplatesEndPoint = 'v2/Astro/Templates';
  private _astroProposalReportEndPoint = 'v2/Astro/ProposalReport';

  // new astro service endpoints
  private _astroOptimizeEndPoint = 'v2/Account/AstroAccounts/Start';
  private _astroStatusEndPoint = 'v2/Account/AstroAccounts/Status';
  private _astroMessageEndPoint = 'v2/Account/AstroAccounts/Message';
  private _astroLogEndPoint = 'v2/Account/AstroAccounts/Log';

  constructor(private _httpClient: OEHttpClient) { }

  optimizationBatch(accountList: string[]): Observable<IAstroBatchOptimization[]> {
    return this._httpClient.postData(this._optimizationBatchEndPoint, { accountIds: accountList });
  }

  getBatchStatusCount(optimizationRequest: IAstroBatchOptimization[], batchName: string): Observable<IAstroBatchStatus> {
    return this._httpClient.postData(`${this._astroStatusEndPoint}/count?uniqueBatchIdentifier=${batchName}`, optimizationRequest);
  }

  getBatchStatusDetail(optimizationRequest: IAstroBatchOptimization, batchName: string): Observable<IAstroBatchStatus> {
    return this._httpClient.postData(`${this._astroStatusEndPoint}/detail?uniqueBatchIdentifier=${batchName}`, optimizationRequest);
  }

  getBatchStatus(batchId: string): Observable<IAstroBatchStatus> {
    return this._httpClient.getData(`${this._optimizationBatchStatusEndPoint}/${batchId}`);
  }

  getBatchSummary(startDate: string, endDate: string): Observable<IAstroBatchesResults[]> {
    return this._httpClient.getData(`${this._optimizationSummaryBatchEndPoint}?startDate=${startDate}&endDate=${endDate}`);
  }

  getBatchSummaryByBatchName(batchName: string): Observable<IAstroBatchesResults> {
    return this._httpClient.getData(`${this._optimizationSummaryBatchEndPoint}/${batchName}`);
  }

  getTradesByAccountIdAndBatchName(firmId: number, accountId: string, batchName: string) {
    return this._httpClient.getData(`${this._optimizationHoldingSummaryAccountEndPoint}/${firmId}/${accountId}/batch/${batchName}`);
  }

  getBatchSummaryByAccountIdAndBatchName(firmId: number, accountId: string, batchName: string) {
    return this._httpClient.getData(`${this._optimizationSummaryAccountEndPoint}/${firmId}/${accountId}/batch/${batchName}`);
  }

  tradeAstroBatchBulk(astroBatches: any) {
    return this._httpClient.postData(`${this._tradeAstroBatchBulkEndPoint}`, astroBatches);
  }

  astroTradeImport(astroBatches: any) {
    return this._httpClient.postData(`${this._astroTradeImportEndPoint}`, astroBatches);
  }

  astroTradeImportSummary(tradeInstanceId: number) {
    return this._httpClient.getData(`${this._astroTradeImportSymmaryEndPoint}/${tradeInstanceId}`);
  }

  getAstroModels() {
    return this._httpClient.getData(this._astroModelEndPoint);
  }

  getAstroLogByAccountIdAndBatchName(connectAccountId: string, batchName: string, connectFirmId: string): Observable<IAstroMessageResponse> {
    return this._httpClient.getData(`${this._astroLogEndPoint}?accountId=${connectAccountId}&batchName=${batchName}&connectFirmId=${connectFirmId}`);
  }

  getAstroMessageByAccountIdAndBatchName(accountId: string, batchName: string, connectFirmId: number): Observable<IAstroMessageResponse> {
    return this._httpClient.getData(`${this._astroMessageEndPoint}/account/${accountId}/batch/${batchName}?connectFirmId=${connectFirmId}`);
  }

  getAstroAlerts(params: { filter?} = {}) {
    return this._httpClient.request('GET', this._astroAccountsEndPoint, { params });
  }

  getAstroAlertFilters() {
    return this._httpClient.request('GET', this._astroAccountFiltersEndPoint);
  }

  updateAstroAccountFields() {
    return this._httpClient.request('POST', this._astroUpdateAccountFieldsEndPoint);
  }

  optimizeTLH(accountList: string[]): Observable<string> {
    return this._httpClient.postData(this._optimizationTaxLossHarvesting, { accountIds: accountList });
  }

  getAstroTemplates() {
    return this._httpClient.request('GET', this._astroTemplatesEndPoint);
  }

  getAstroProposalReport() {
    return this._httpClient.getData(this._astroProposalReportEndPoint);
  }

  getAstroSecuritiesRestrictions(accountId: number): Observable<IAstroHoldingRestriction[]> {
    const endPoint = `v2/Account/AstroAccounts/${accountId}/SecuritiesRestrictions`;
    return this._httpClient.get<IAstroHoldingRestriction[]>(endPoint);
  }

  mergeSecuritiesRestrictions(securityPreferences: any[], securitiesRestrictions: IAstroHoldingRestriction[]) {
    if (!securitiesRestrictions || !securityPreferences) {
      return;
    }

    securitiesRestrictions.forEach(restriction => {
      const existingPreference = securityPreferences.find(x => x.symbol === restriction.ticker);
      if (existingPreference) {
        existingPreference.astroRestrictionTypeMarsValue = restriction.restrictionType;
        existingPreference.astroRestrictionMinMarsValue = restriction.minimumPercent;
        existingPreference.astroRestrictionMaxMarsValue = restriction.maximumPercent;
      } else {
        const newPref = {} as ISecurityPreference;

        newPref.id = restriction.securityId;
        newPref.securityName = restriction.securityName;
        newPref.symbol = restriction.ticker;
        newPref.securityType = restriction.securityType;
        newPref.astroRestrictionTypeMarsValue = restriction.restrictionType;
        newPref.astroRestrictionMinMarsValue = restriction.minimumPercent;
        newPref.astroRestrictionMaxMarsValue = restriction.maximumPercent;
        newPref.alternatives = [];
        securityPreferences.push(newPref);
      }
    });
  }

  // new astro service
  optimize(astroOptimizeRequest: IAstroOptimizeRequest): Observable<IAstroBatchOptimization[]> {
    return this._httpClient.postData(this._astroOptimizeEndPoint, astroOptimizeRequest);
  }
}
