import { Component, inject, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from '../../core/base.component';
import { DashboardService } from '../../services/dashboard.service';
import {
  IDashboardSummary,
  IAnalysisSummary,
  ICheckImportProcess,
  IReverseSyncHistory,
  IAnalyticsStatus,
  IAcceptImportStatus,
  IImportHistory, IModalSummary, IAnalyticsRunHistory
} from '../../models/mainDashboard';
import { NotificationService } from '../../services/notification.service';
import { GridOptions, ColDef, CsvExportParams, GridApi, GridReadyEvent } from '@ag-grid-community/core';
import * as Consts from '../../libs/app.constants';
import { MilliSecondsToTimePipe } from '../../pipes/FilterPipe';
import { BehaviorSubject, Subject, Subscription, map, tap } from 'rxjs';
import { v1 as UUID } from 'uuid';
import { ErrorLogService } from '../../services/errorlog.service';
import { MenuItem } from 'primeng/api';
import { SessionHelper } from '../../core';
import { BaseGridConfiguration } from '../../shared/gridextensions/basegrid';
import { Utils as Util } from '../../core/functions';
import { AnalyticsHelper } from '../../shared/analytics-duration/analytics-helper';
import { IAnalyticsNotification, INotificationVM } from '../../models/notification';
import { AnalyticsService } from '../../services/analytics.service';
import { UserType } from '../../libs/app.constants';
import moment from 'moment';
import {SplitIoService} from "../../core/feature-flag";

@Component({
  selector: 'eclipse-main-dashboard',
  templateUrl: './main-dashboard.component.html',
  styleUrls: ['./main-dashboard.component.scss'],
  encapsulation: ViewEncapsulation.None // to share CSS between parent and child components
})
export class MainDashboardComponent extends BaseComponent {
  static PRELOAD_FEATURE_FLAGS: string[] = [
    'TEXP_eclipse_dashboard_top_8116',
    'TEXP_sync_history_dashboard_9746'
  ];
  private readonly featureFlags: { [key: string]: boolean };

  dashboardData$: BehaviorSubject<IDashboardSummary> = new BehaviorSubject<IDashboardSummary>(undefined);
  private importDateSubject = new Subject<any>();
  summaryData: IAnalysisSummary;
  portfolioData: IModalSummary;
  accountData: IModalSummary;
  modelData: IModalSummary;

  importOptions: MenuItem[] = [];
  disableFullImport: boolean = false;
  disableStartImportButton: boolean = true;
  disableAnalyticsRelated: boolean = false;
  showRefreshAnalytics: boolean = false;
  analyticsDuration: string;
  durationInterval: number;
  showAnalyticsDuration: boolean = false;
  isAnalyticsCompleted: boolean;
  actionMenuItems: MenuItem[];
  analyticsRunHistoryInterval: number;
  importHistoryLabel: string = 'Import History';
  currentTab: 'ImportHistory' | 'ReverseSyncHistory' = 'ImportHistory';
  selectedTabIndex: number = 0;


  public get disableStartImport(): boolean {
    // disable the Start Import if the button is disabled or every import option is disabled
    return this.disableStartImportButton || this.disableAnalyticsRelated || this.importOptions.every(option => option.disabled);
  }

  // Reverse Sync
  showReverseSyncHistory: boolean = false;
  public gridApiReverseSync: GridApi;
  reverseSyncGridOptions: GridOptions;
  reverseSyncColumnDefs: ColDef[];
  reverseSyncHistoryData: IReverseSyncHistory[] = [];
  exportReverseSyncLogsSubscription: Subscription;
  selectedReverseSyncRows: any[];

  // Import History
  importHistoryData: IImportHistory[] = [];
  showImportHistory: boolean = false;
  showComImportHistory: boolean = false;
  public gridApiImportHistory: GridApi;
  importHistoryGridOptions: GridOptions;
  importHistoryColumnDefs: ColDef[];
  exportImportLogsSubscription: Subscription;
  selectedImportRows: any[];
  startDate: Date = new Date();
  endDate: Date = new Date();
  maxDate = new Date();
  startDateCombine: Date = new Date();
  endDateCombine: Date = new Date();

  showNoMergedFirmMessage: boolean = false;
  hasNoOCFirmId: boolean = true;
  enableAcceptImportButton: boolean = false;
  acceptImportStatusMessage: string = '';
  enableResetAnalysis: boolean = false;
  displayResetAnalytics: boolean = false;
  displayFullImport: boolean = false;
  displayPartialImport: boolean = false;
  displayDeltaImport: boolean = false; // This value will be used to show/hide the "Start Import" button

  progressNotifications$: Subscription;
  refreshDashboardNotifications$: Subscription;
  lastImportDateNotifications$: Subscription;
  isResetAnalytics: boolean;
  progressCompleted: any;
  canExportLogs = false;
  analyticsPortfolioStatusSubscription: Subscription;
  checkPermissionForResetAnalytics: boolean = false;

  public useNewDashboard: boolean = false;
  public showCombinedHistory: boolean = false;

  private readonly notificationService: NotificationService = inject(NotificationService);
  private readonly _dashboardService: DashboardService = inject(DashboardService);
  private readonly errorLogService: ErrorLogService = inject(ErrorLogService);
  private readonly _analyticsService: AnalyticsService = inject(AnalyticsService);
  private readonly _activatedRoute: ActivatedRoute = inject(ActivatedRoute);

  constructor(public readonly sessionHelper: SessionHelper, private readonly _splitIoService: SplitIoService) {
    super('', sessionHelper);
    this.featureFlags = this._activatedRoute.snapshot.data['featureFlags'] || {};
    this.reverseSyncGridOptions = {
      ...this.defaultFilterableGridOptions,
      statusBar: {
        ...BaseGridConfiguration.statusBarRowCounts
      }
    };
    this.importHistoryGridOptions = {
      ...this.defaultFilterableGridOptions,
      statusBar: {
        ...BaseGridConfiguration.statusBarRowCounts
      }
    };
    this.createReverseSyncColumnDefs();
    this.createImportHistoryColumnDefs();

    this.useNewDashboard = !!this.featureFlags['TEXP_eclipse_dashboard_top_8116'];
    this.showCombinedHistory = !!this.featureFlags['TEXP_sync_history_dashboard_9746'];
    this.checkPermissionForResetAnalytics = !!this._splitIoService.featureFlags['TEXP_hide_reset_analytics_9763'];
  }

  ngOnInit() {
    const user = this._sessionHelper.getUser();
    // Check the user has the privilege to see the refresh analytics menu item
    const rolePrivileges = this._sessionHelper.getPrivileges();
    const refreshPrivilege = rolePrivileges.find(t => t.code.toLocaleUpperCase() === 'REFANALYTICS');
    if (!!refreshPrivilege) {
      this.showRefreshAnalytics = <boolean>refreshPrivilege.canRead;
    }

    // check the user has the privilege to see the data import buttons individually
    const fullImportPrivilege = rolePrivileges.find(t => t.code.toLocaleUpperCase() === 'FULLIMPORT');
    if (!!fullImportPrivilege) {
      this.displayFullImport = !!fullImportPrivilege.canRead;
    }

    // Check that the user has the privilege to use the delta import method
    const deltaImportPrivilege = rolePrivileges.find(t => t.code.toLocaleUpperCase() === 'DELTAIMPORT');
    if (!!deltaImportPrivilege) {
      this.displayDeltaImport = !!deltaImportPrivilege.canRead;
    }

    this.setImportOptions();

    // Reset analytics item should be visible for only Orion Admin and Firm Admin role users only
    if (!!user) {
      if(this.checkPermissionForResetAnalytics) {
        const resetAnalyticsPermission = Util.getPermission(Consts.PRIV_RESETANALYTICS);
        this.displayResetAnalytics = !!resetAnalyticsPermission?.canUpdate;
      }
      else {
        this.displayResetAnalytics = [+UserType.FirmAdmin, +UserType.OrionAdmin].includes(user.role.roleTypeId);
      }
    }

    // Check that the user has the privilege to export import and reverse sync logs
    const logExportEnabled = rolePrivileges.find(t => t.code.toLocaleUpperCase() === 'EXPORTLOGS');
    if (typeof (logExportEnabled) !== 'undefined' && !!logExportEnabled) {
      this.canExportLogs = logExportEnabled.canRead;
    }

    if(this.showCombinedHistory) {
      this.importHistoryLabel = 'Sync History';
    }

    this.loadDashboardData();
    this.loadNotifications();
    this.refreshOverviewDashboardFallback();
    this.subscribeAnalyticsPortfolioStatusNotification();
    this.subscribeLastImportDateNotifications();
    this.setActionMenuOptions();
    this.getAnalyticsRunHistory();
    this.checkAnalyticsRunHistoryStatusInEveryFiveMinutes();
  }

  public setActionMenuOptions() {
    const actionItems: MenuItem[] = [];
    if (this.showRefreshAnalytics) {
      actionItems.push({
          label: 'Reset Analytics',
          //icon: 'fas fa-sync',
          command: () => {
            this.onMenuClick('RefreshAnalytics');
          }
        });
    }
    if (this.displayResetAnalytics) {
      actionItems.push({
        label: 'Refresh Database Analytics',
        command: () => {
          this.onMenuClick('ResetAnalytics');
        }
      });
    }
    actionItems.push({
      label: 'View Analytics Error Log',
      routerLink: '/analyticserrorlogs'
    });

    if(!this.showCombinedHistory) {
      actionItems.push({
        label: 'View Reverse Sync History',
        command: () => {
          this.openReverseSyncHistory();
        }
      });
    }

    this.actionMenuItems = actionItems;
  }

  public setImportOptions() {
    this.importOptions = [];
    //Full import can be displayed for all firms IF the user has Full Import Privilage.
    //All imports will be V2 Only
    if (this.displayFullImport) {
      this.importOptions.push({
        label: 'Full',
        disabled: this.disableFullImport || this.hasNoOCFirmId,
        command: () => {
            // v2 Full import
            this.startImport('Full');
        }
      });
    }
    // Delta import can be displayed for all firms.  Add it only if the user has the Delta Import privilege.
    if (this.displayDeltaImport) {
      this.importOptions.push({
        label: 'Delta',
        disabled: this.disableStartImportButton || this.hasNoOCFirmId,
        command: () => {
          this.startImport('Delta');
        }
      });
    }
  }

  /** Loads dashboard data */
  private loadDashboardData() {
    this.getDashboardSummary();
    this.getDataImportStatus();
    this.getAnalyticsStatus();
    this.getAcceptImportStatus();
  }

  private refreshImportAnalyticsStatus() {
    this.getDataImportStatus();
    this.getAnalyticsStatus();
    this.getAcceptImportStatus();
  }

  ngAfterViewInit() {
    const user = this._sessionHelper.getUser();
    if (user.role.roleTypeId === Consts.UserType.FirmAdmin || user.role.roleTypeId === Consts.UserType.OrionAdmin) {
      const mergedFirmsDetails = this._sessionHelper.get('mergedFirmsDetails');
      if (typeof mergedFirmsDetails !== undefined && mergedFirmsDetails !== null) {
        this.showNoMergedFirmMessage = mergedFirmsDetails['showPopup'];
        this._sessionHelper.set('mergedFirmsDetails', {showPopup: false, showLayoutMessage: true});
      }
    }
    // disable all import and analytics related buttons on
    this.hasNoOCFirmId = (!user.ocFirmId || !user.ocFirmId.length);
  }

  public importDate$ = this.importDateSubject
    .pipe(
      map(dt => new Date(dt)),
      tap(dt => {
        if (this.summaryData) {
          this.summaryData.lastImportTimezone = dt?.toLocaleDateString('en-US', {timeZoneName: 'short'})?.split(' ')?.pop() || '';
          this.summaryData.lastImportWarning = !!dt && !this.isToday(dt);
        }
      })
    );

  public getDashboardSummary() {
    this._dashboardService.getDashboardSummary()
      .subscribe((responseData: IDashboardSummary) => {
        this.dashboardData$.next(responseData);
        this.summaryData = responseData.importAnalysisSummary;
        this.importDateSubject.next(this.summaryData.lastImportedDate);
        this.portfolioData = responseData.warningsSummary.find(s => s.moduleName === 'Portfolios');
        this.accountData = responseData.warningsSummary.find(s => s.moduleName === 'Accounts');
        this.modelData = responseData.warningsSummary.find(s => s.moduleName === 'Models');
        this._dashboardService.refreshDashboardData();
      });
  }

  private isToday(date: Date) {
    return moment(date).isSame(moment(), 'day');
  }

  /** Get analytics status */
  getAnalyticsStatus() {
    // Getting analytics status, based on that we would disable/enable the analytics related links/buttons
    this._dashboardService.getAnalyticsStatus()
      .subscribe((result: IAnalyticsStatus) => {
        this.disableAnalyticsRelated = result.isAnalysisRunning;
        this.disableFullImport = !!result.isAnalysisRunning; // Do not allow import to run while Analytics is in progress
        this.disableStartImportButton = this.disableFullImport;
        this.setImportOptions();
      });
  }

  /** Get Data import status */
  getDataImportStatus() {
    // Check Import Process is Running
    this._dashboardService.checkImportProcess()
      .subscribe((result: ICheckImportProcess) => {
        this.disableFullImport = this.disableAnalyticsRelated ? true : !!result.isImportRunning;
        this.disableStartImportButton = this.disableFullImport;
        this.setImportOptions();
      });
  }

  private getAnalyticsRunHistory(): void {
    this._dashboardService.getAnalyticsRunHistory()
      .subscribe((result: IAnalyticsRunHistory[]): void => {
        if (result?.length) {
          this.showAnalyticsDuration = true;
          const dates = result.map(analyticsRunHistory => analyticsRunHistory.startTime);
          const dateObjects = dates.map(date => AnalyticsHelper.convertUTCDateToLocalDate(date));
          const startedDate = new Date(Math.max.apply(null, dateObjects));
          clearInterval(this.durationInterval);
          this.analyticsDuration = null;
          this.durationInterval = window.setInterval(() => this.analyticsDuration = AnalyticsHelper.getDurationTimer(startedDate), 1000);
        }
      });
  }

  /** Get Accept Import button status */
  getAcceptImportStatus() {
    this._dashboardService.acceptImportStatus()
      .subscribe((result: IAcceptImportStatus) => {
        this.enableAcceptImportButton = !!result.enableAcceptImportButton;
        if (this.enableAcceptImportButton) {
          this.acceptImportStatusMessage = 'New Data is ready to import';
        }
      });
  }

  /** Accept Import process */
  public acceptImportProcess() {
    this._dashboardService.acceptImportProcess()
      .subscribe();
  }

  /** on menu links click, specific action will be performed */
  onMenuClick(action) {
    switch (action) {
      case 'RefreshAnalytics':
        this.refreshAnalyticsData();
        break;
      case 'ResetAnalytics':
        this.resetAnalytics();
        break;
    }
  }

  /** REFRESH ANALYTICS */
  public refreshAnalyticsData() {
    this._dashboardService.refreshAnalytics()
      .subscribe(() => {
        this.disableAnalyticsRelated = true;
      });
  }


  /** START NEW IMPORT - this method is used by the new v2 version of the "start import" method that replaces full/partial imports */
  public startImport(importType: string) {
    const generatedUUID = UUID();
    const initialRequestObj = {
      CorrelationId: generatedUUID,
      Type: importType
    };
    this.disableStartImportButton = true;
    this._dashboardService.initiateNewImport(initialRequestObj)
      .subscribe((response: any) => {
        if (!!response && response.id > 0) {
          const importRequestObj = {CorrelationId: generatedUUID};
          this._dashboardService.continueNewImport(importRequestObj)
            .subscribe();
        }
      });
  }

  /**CALCULATE PERCENTAGE */
  calculatePercentage(actualValue, totalValue) {
    return ((actualValue / totalValue) * 100);
  }

  /**
   * Notification subscribe
   * Invoke the subscribe event when ever there is notification
   */
  loadNotifications() {
    this.progressNotifications$ = this.notificationService.getProgressNotification.subscribe(notification => {
      const notificationData = notification;
      if (!!notificationData) {
        if (notificationData['typeId'] === Consts.EmitNotificationType.ProgressWindow && notificationData['progressNotification']['progress'] !== 100) {
          switch (notificationData['code']) {
            case Consts.NotificationCode.fullImport:
            case Consts.NotificationCode.partialImport:
              if (notificationData['progressNotification']['progress'] < 60 && notificationData['progressNotification']['status'] === 'IN_PROGRESS') {
                this.disableFullImport = true;
                this.disableStartImportButton = this.disableFullImport;
                this.enableAcceptImportButton = false;
              } else if (notificationData['progressNotification']['progress'] === 60) {
                this.disableFullImport = true;
                this.disableStartImportButton = this.disableFullImport;
                this.enableAcceptImportButton = true;
                this.acceptImportStatusMessage = 'New Data is ready to import';
              } else { // This will hit for Accept import notifications. because the notification code is "dataimport" and progress is 61% to 100% only.
                this.enableAcceptImportButton = false;
                this.acceptImportStatusMessage = 'Data import is in progress';
              }
              if (notificationData['code'] === Consts.NotificationCode.fullImport) {
                this.disableAnalyticsRelated = true;
              }
              this.setImportOptions();
              break;
            case Consts.NotificationCode.fullAnalysis:
              this.disableFullImport = true;
              this.disableStartImportButton = this.disableFullImport;
              this.enableAcceptImportButton = false;
              this.disableAnalyticsRelated = true;
              this.setImportOptions();
              break;
            case Consts.NotificationCode.partialAnalysis:
              this.disableAnalyticsRelated = true;
              this.disableFullImport = true; // Do not allow import to run while Analytics is in progress
              this.disableStartImportButton = this.disableFullImport;
              this.setImportOptions();
              break;
            default:
              this.disableFullImport = false;
              this.disableStartImportButton = this.disableFullImport;
              this.enableAcceptImportButton = false;
              this.disableAnalyticsRelated = false;
              this.setImportOptions();
              break;
          }
        } else if (notificationData['typeId'] === Consts.EmitNotificationType.ProgressWindow && notificationData['progressNotification']['progress'] === 100) {
          this.disableFullImport = false;
          this.disableStartImportButton = this.disableFullImport;
          this.enableAcceptImportButton = false;
          this.disableAnalyticsRelated = false;
          this.setImportOptions();
          // For accept import notifications, code "dataimport" and progress is 100% => the accept import button should be disabled
          if (notificationData['code'] === Consts.NotificationCode.fullImport) {
            this.enableAcceptImportButton = false; // Data Import > Accept Import button is enabled
            if (notificationData['progressNotification']['status'] === Consts.ProgressNotificationStatus.COMPLETED) {
              this.acceptImportStatusMessage = 'All data has been imported successfully';
            }
          }
          // For post import analysis progress notifications and it's completed then we reload the dashboard data
          // possible type will get from notifications as "PostImport Analysis" after data import
          if (notificationData['progressNotification']['type'] === 'PostImport Analysis' && notificationData['progressNotification']['status'] === 'Completed') {
            if (this.progressCompleted !== notificationData['progressNotification']['processId']) { // Call getDashboardSummary only when its not called previously.
              this.loadDashboardData();
            }
            this.progressCompleted = notificationData['progressNotification']['processId'];
          }
        } else {
          this.disableFullImport = false;
          this.disableStartImportButton = this.disableFullImport;
          this.enableAcceptImportButton = false;
          this.disableAnalyticsRelated = false;
          this.setImportOptions();
        }
      }
    });
  }

  /** Create column headers for agGrid */
  createReverseSyncColumnDefs() {
    const self = this;
    this.reverseSyncColumnDefs = [
      <ColDef>{
        headerName: 'ID',
        field: 'id',
        width: 120,
        resizable: true,
        cellClass: 'text-center',
        filter: 'agNumberColumnFilter'
      },
      <ColDef>{
        headerName: 'Description',
        field: 'description',
        tooltipField: 'description',
        cellClass: 'text-center',
        width: 250,
        filter: 'agTextColumnFilter'
      },
      <ColDef>{
        headerName: 'Status',
        field: 'status',
        tooltipField: 'status',
        cellClass: 'text-center',
        width: 250,
        filter: 'agTextColumnFilter'
      },
      <ColDef>{
        headerName: 'Message',
        field: 'message',
        tooltipField: 'message',
        width: 550,
        cellClass: 'text-center',
        filter: 'agTextColumnFilter'
      },
      <ColDef>{
        headerName: 'Created By',
        field: 'user',
        tooltipField: 'user',
        width: 200,
        cellClass: 'text-center',
        filter: 'agTextColumnFilter'
      },
      <ColDef>{
        headerName: 'Created On',
        field: 'startDateTime',
        tooltipField: 'startDateTime',
        width: 210,
        cellClass: 'text-center',
        filter: 'agTextColumnFilter',
        comparator: this.dateComparator,
        valueGetter: function (params) {
          return self.dateTimeFormatOnGridFilter(params.data.startDateTime);
        }
      },
      <ColDef>{
        headerName: 'Ended On',
        field: 'endDateTime',
        tooltipField: 'endDateTime',
        width: 210,
        cellClass: 'text-center',
        filter: 'agTextColumnFilter',
        comparator: this.dateComparator,
        valueGetter: function (params) {
          return self.dateTimeFormatOnGridFilter(params.data.endDateTime);
        }
      },
    ];
  }

  /** Load the Reverse Sync History Data */
  getReverseSyncHistory() {
    this._dashboardService.getReverseSyncHistory()
      .subscribe((responseData: IReverseSyncHistory[]) => {
        this.reverseSyncHistoryData = responseData;
        this.gridApiReverseSync?.sizeColumnsToFit();
      });
  }

  /** Load the Reverse Sync History Data */
  getReverseSyncHistory_9943() {
    let startDateParam = Util.getUTCStartDateAndTime(this.startDateCombine);
    let endDateParam = Util.getUTCEndDateAndTime(this.endDateCombine);

    this._dashboardService.getReverseSyncHistory_9943(startDateParam, endDateParam)
      .subscribe((responseData: IReverseSyncHistory[]) => {
        this.reverseSyncHistoryData = responseData;
        this.gridApiReverseSync?.sizeColumnsToFit();
      });
  }

  /** Open Reverse Sync History popup */
  openReverseSyncHistory() {
    this.showReverseSyncHistory = true;
    this.getReverseSyncHistory();
  }

  resetAnalytics() {
    this._dashboardService.resetAnalytics()
      .subscribe(() => {
        this.getResetAnalyticsStatus();
        this.resetAnalyticsRunHistory();
      });
  }

  getResetAnalyticsStatus() {
    this._dashboardService.resetAnalyticsStatus()
      .subscribe();
  }

  resetAnalyticsRunHistory() {
    this._analyticsService.resetAnalyticsRunHistory()
      .subscribe();
  }

  /** Open Import History popup */
  openImportHistory() {
    if(this.showCombinedHistory) {
      this.startDateCombine = new Date();
      this.endDateCombine = new Date();
      this.showComImportHistory = true;
      this.getImportHistory();
      this.getReverseSyncHistory_9943();
    }
    else {
      this.showImportHistory = true;
      this.importHistoryData = [];
      this.getImportHistory();
    }
  }

  /** Create columnd defs for import history grid */
  createImportHistoryColumnDefs() {
    const self = this;
    this.importHistoryColumnDefs = [
      <ColDef>{
        headerName: 'ID',
        field: 'id',
        width: 90,
        cellClass: 'text-center',
        filter: 'agNumberColumnFilter',
        sort: 'desc'
      },
      <ColDef>{
        headerName: 'Type',
        enableRowGroup: true,
        field: 'type',
        width: 100,
        cellClass: 'text-center',
        filter: 'agTextColumnFilter',
      },
      <ColDef>{
        headerName: 'User Id',
        enableRowGroup: true,
        field: 'userId',
        width: 100,
        cellClass: 'text-center',
        filter: 'agNumberColumnFilter'
      },
      <ColDef>{
        headerName: 'Start Extract Time',
        field: 'startExtractTime',
        cellClass: 'text-center',
        filter: 'agTextColumnFilter',
        comparator: this.dateComparator,
        valueGetter: function (params) {
          return params.data ? self.dateTimeFormatOnGridFilter(params.data.startExtractTime) : null;
        }
      },
      <ColDef>{
        headerName: 'End Extract Time',
        field: 'endExtractTime',
        cellClass: 'text-center',
        filter: 'agTextColumnFilter',
        comparator: this.dateComparator,
        valueGetter: function (params) {
          return params.data ? self.dateTimeFormatOnGridFilter(params.data.endExtractTime) : null;
        }
      },
      <ColDef>{
        headerName: 'Total Extract Time(hours:min:sec:ms)',
        field: 'totalExtractTime',
        cellClass: 'text-center',
        filter: 'agNumberColumnFilter',
        valueGetter: function (params) {
          return params.data ? self.milliSecondsToTime(params.data.totalExtractTime) : null;
        }
      },
      <ColDef>{
        headerName: 'Start Import Time',
        field: 'startImportTime',
        width: 250,
        suppressSizeToFit: true,
        cellClass: 'text-center',
        filter: 'agTextColumnFilter',
        comparator: this.dateComparator,
        valueGetter: function (params) {
          return params.data ? self.dateTimeFormatOnGridFilter(params.data.startImportTime) : null;
        }
      },
      <ColDef>{
        headerName: 'End Import Time',
        field: 'endImportTime',
        width: 250,
        suppressSizeToFit: true,
        cellClass: 'text-center',
        filter: 'agTextColumnFilter',
        comparator: this.dateComparator,
        valueGetter: function (params) {
          return params.data ? self.dateTimeFormatOnGridFilter(params.data.endImportTime) : null;
        }
      },
      <ColDef>{
        headerName: 'Total Import Time(hours:min:sec:ms)',
        field: 'totalImportTime',
        cellClass: 'text-center',
        filter: 'agNumberColumnFilter',
        valueGetter: function (params) {
          return params.data ? self.milliSecondsToTime(params.data.totalImportTime) : null;
        }
      },
      <ColDef>{
        headerName: 'Status',
        enableRowGroup: true,
        field: 'status',
        width: 150,
        cellClass: 'text-center',
        filter: 'agTextColumnFilter'
      },
      <ColDef>{
        headerName: 'Completion %',
        field: 'percentComplete',
        width: 115,
        cellClass: 'text-center',
        filter: 'agNumberColumnFilter',
        valueFormatter: function (params) {
          if (!params || params.value === null || params.value === undefined) {
            return null;
          }
          return `${params.value.toFixed()} %`;
        }
      },
      <ColDef>{
        headerName: 'Error Message',
        enableRowGroup: true,
        width: 375,
        field: 'reason',
        filter: 'agTextColumnFilter'
      },
      <ColDef>{
        headerName: 'Start Analytics Time',
        field: 'startAnalyticsTime',
        cellClass: 'text-center',
        filter: 'agTextColumnFilter',
        comparator: this.dateComparator,
        valueGetter: function (params) {
          return params.data ? self.dateTimeFormatOnGridFilter(params.data.startAnalyticsTime) : null;
        }
      },
      <ColDef>{
        headerName: 'End Analytics Time',
        field: 'endAnalyticsTime',
        cellClass: 'text-center',
        filter: 'agTextColumnFilter',
        comparator: this.dateComparator,
        valueGetter: function (params) {
          return params.data ? self.dateTimeFormatOnGridFilter(params.data.endAnalyticsTime) : null;
        }
      },
      <ColDef>{
        headerName: 'Total Analytics Time(hours:min:sec:ms)',
        field: 'totalAnalyticsTime',
        cellClass: 'text-center',
        filter: 'agTextColumnFilter',
        valueGetter: function (params) {
          return params.data ? self.milliSecondsToTime(params.data.totalAnalyticsTime) : null;
        }
      },
      <ColDef>{
        headerName: 'Start Trade Generation Time',
        field: 'startTradeGenerationTime',
        cellClass: 'text-center',
        filter: 'agTextColumnFilter',
        comparator: this.dateComparator,
        valueGetter: function (params) {
          return params.data ? self.dateTimeFormatOnGridFilter(params.data.startTradeGenerationTime) : null;
        }
      },
      <ColDef>{
        headerName: 'End Trade Generation Time',
        field: 'endTradeGenerationTime',
        cellClass: 'text-center',
        filter: 'agTextColumnFilter',
        comparator: this.dateComparator,
        valueGetter: function (params) {
          return params.data ? self.dateTimeFormatOnGridFilter(params.data.endTradeGenerationTime) : null;
        }
      },
      <ColDef>{
        headerName: 'Total Trade Generation Time(hours:min:sec:ms)',
        field: 'totalTradeGenerationTime',
        cellClass: 'text-center',
        filter: 'agTextColumnFilter',
        valueGetter: function (params) {
          return params.data ? self.milliSecondsToTime(params.data.totalTradeGenerationTime) : null;
        }
      }
    ];
  }

  onImportGridReady(params: GridReadyEvent) {
    this.gridApiImportHistory = params.api;
    this.gridApiImportHistory?.sizeColumnsToFit();
  }

  onReverseSyncGridReady(params: GridReadyEvent) {
    this.gridApiReverseSync = params.api;
    this.gridApiReverseSync?.sizeColumnsToFit();
  }

  onImportHistoryRowSelected() {
    this.selectedImportRows = this.gridApiImportHistory.getSelectedRows();
  }

  onReverseSyncRowSelected() {
    this.selectedReverseSyncRows = this.gridApiReverseSync.getSelectedRows();
  }

  /**Datepicker date select event */
  dateSelect(param, type) {
    if (type === 'start') {
      this.startDate = param;
    } else {
      this.endDate = param;
    }
  }

  /** Disable view button based on the start and end dates provided by end user */
  validateStartAndEndDates() {
    return (new Date(this.endDate) < new Date(this.startDate));
  }

  validateStartAndEndDates_9746() {
    return (new Date(this.endDateCombine) < new Date(this.startDateCombine));
  }

  getImportHistory() {
    let startDateParam = Util.getUTCStartDateAndTime(this.startDate);
    let endDateParam = Util.getUTCEndDateAndTime(this.endDate);

    if(this.showCombinedHistory) {
      startDateParam = Util.getUTCStartDateAndTime(this.startDateCombine);
      endDateParam = Util.getUTCEndDateAndTime(this.endDateCombine);
    }
    this._dashboardService.getImportHistoryData(startDateParam, endDateParam)
      .subscribe((result: IImportHistory[]) => {
        this.importHistoryData = result;
      });
  }

  viewImportHistoryData() {
    this.getImportHistory();
  }

  viewImportHistoryData_9746() {
    this.getImportHistory();
    this.getReverseSyncHistory_9943();
  }

 downloadImportLogs(): void {
    if (!this.selectedImportRows || this.selectedImportRows.length !== 1) {
      return;
    }
    this.exportImportLogsSubscription?.unsubscribe();
    const importId = this.selectedImportRows[0].id;
    this.exportImportLogsSubscription = this.errorLogService.exportImportLog(importId)
      .subscribe(result => {
        this.downloadByteArrayFile(result.fileContents, `ImportLog_${importId}.zip`, 'application/zip');
      });
  }

  downloadSyncHistory(): void {
    if (this.currentTab === 'ImportHistory' && this.selectedImportRows?.length === 1) {
      this.exportImportLogsSubscription?.unsubscribe();
      const importId = this.selectedImportRows[0].id;
      this.exportImportLogsSubscription = this.errorLogService.exportImportLog(importId)
        .subscribe(result => {
          this.downloadByteArrayFile(result.fileContents, `ImportLog_${importId}.zip`, 'application/zip');
        });
    }

    if (this.currentTab === 'ReverseSyncHistory' && this.selectedReverseSyncRows?.length) {
      this.exportReverseSyncLogsSubscription?.unsubscribe();
      this.exportReverseSyncLogsSubscription = this.errorLogService.exportReverseSyncLogs(this.selectedReverseSyncRows.map(i => i.id))
        .subscribe(result => {
          this.downloadByteArrayFile(result.fileContents, 'ReverseSyncLogs.zip', 'application/zip');
        });
    }
  }

  exportImportLogsToExcel(): void {
    const params = <CsvExportParams>{
      skipFooters: true,
      skipGroups: true,
      fileName: 'ImportLogs.csv'
    };
    this.gridApiImportHistory.exportDataAsCsv(params);
  }

  exportSyncHistoryToExcel(): void {
    if(this.currentTab === 'ImportHistory' && this.importHistoryData?.length) {
      const importParams = <CsvExportParams>{
        skipFooters: true,
        skipGroups: true,
        fileName: 'ImportLogs.csv'
      };
      this.gridApiImportHistory.exportDataAsCsv(importParams);
    }

    if(this.currentTab === 'ReverseSyncHistory' && this.reverseSyncHistoryData?.length) {
      const reverseSyncParams = <CsvExportParams>{
        skipFooters: true,
        skipGroups: true,
        fileName: 'ReverseSyncHistory.csv'
      };
      this.gridApiReverseSync.exportDataAsCsv(reverseSyncParams);
    }
  }

  downloadReverseSyncLogs(): void {
    if (!this.selectedReverseSyncRows || !this.selectedReverseSyncRows.length) {
      return;
    }
    this.exportReverseSyncLogsSubscription?.unsubscribe();
    this.exportReverseSyncLogsSubscription = this.errorLogService.exportReverseSyncLogs(this.selectedReverseSyncRows.map(i => i.id))
      .subscribe(result => {
        this.downloadByteArrayFile(result.fileContents, 'ReverseSyncLogs.zip', 'application/zip');
      });
  }

  exportReverseSyncToExcel(): void {
    const params = <CsvExportParams>{
      skipFooters: true,
      skipGroups: true,
      fileName: 'ReverseSyncHistory.csv'
    };
    this.gridApiReverseSync.exportDataAsCsv(params);
  }

  milliSecondsToTime(milliseconds) {
    if (milliseconds !== null && typeof milliseconds !== undefined && milliseconds !== '') {
      if (isNaN(milliseconds)) {
        return;
      }
      return new MilliSecondsToTimePipe().transform(milliseconds);
    }
  }

  /** Refresh the dashbaord to reset all import/analysis related buttons to handle analysis process broken cases */
  refreshOverviewDashboardFallback() {
    this.refreshDashboardNotifications$ = this.notificationService.refreshOverviewDashboardEmitter.subscribe(() => {
      this.refreshImportAnalyticsStatus();
    });
  }

  ngOnDestroy() {
    // unsubscribing to stop receiving notifications from getProgressNotification EventEmitter
    this.progressNotifications$?.unsubscribe();
    this.refreshDashboardNotifications$?.unsubscribe();
    this.lastImportDateNotifications$?.unsubscribe();
    this.analyticsPortfolioStatusSubscription?.unsubscribe();
    clearInterval(this.analyticsRunHistoryInterval);
  }

  private subscribeAnalyticsPortfolioStatusNotification(): void {
    this.analyticsPortfolioStatusSubscription = this.notificationService.analyticsPortfolioStatus
      .subscribe((notification: INotificationVM): void => {
        const isFullAnalytics = AnalyticsHelper.isFullAnalytics(notification?.analyticsNotification?.trigger);
        if (!isFullAnalytics) {
          return;
        }
        this.getAnalyticsRunHistory();
        this.updateAnalyticsData(notification?.analyticsNotification);
        this.getAnalyticsStatus();
      });
  }

  private updateAnalyticsData(analyticsNotification: IAnalyticsNotification): void {
    if (analyticsNotification?.isAnalyticsReset) {
      this.showAnalyticsDuration = false;
      this.isResetAnalytics = true;
    } else if (analyticsNotification?.isAnalyticsCompleted) {
      this.showAnalyticsDuration = false;
      this.isAnalyticsCompleted = true;
      this.loadDashboardData();
      clearInterval(this.durationInterval);
    }
  }

  subscribeLastImportDateNotifications() {
    this.lastImportDateNotifications$ = this.notificationService.lastImportDateEmitter.subscribe(notification => {
      this.summaryData.lastImportedDate = notification.lastImportedDate;
      this.importDateSubject.next(this.summaryData.lastImportedDate);
    });
  }

  /**
   * This method is used to check the analytics run history status every 5 minutes if the duration is running.
   */
  private checkAnalyticsRunHistoryStatusInEveryFiveMinutes(): void {
    clearInterval(this.analyticsRunHistoryInterval);
    this.analyticsRunHistoryInterval = window.setInterval((): void => {
      if (this.showAnalyticsDuration) {
        this._dashboardService.getAnalyticsRunHistory()
          .subscribe((result: IAnalyticsRunHistory[]): void => {
            if (!result?.length) {
              this.showAnalyticsDuration = false;
              clearInterval(this.durationInterval);
            }
          });
      }
    }, 300000); // Five Minutes
  }

  onChange(evt) {
    switch (evt.index) {
      case 0:
        this.onTabChanged('ImportHistory');
        break;
      case 1:
        this.onTabChanged('ReverseSyncHistory');
        break;
    }
  }

  onTabChanged(tabName): void {
    this.currentTab = tabName;
  }
}
