import { IModelExcelImport } from '../models/modeling/modelexcelimport';
import { IAssignPortfolioToModel } from '../models/modeling/assignportfoliotomodel';
import { IAssignSleeveToModel } from '../models/modeling/assignsleevetomodel';
import {IModelAdd, ICreateNewModel, ISubModel, IOriginalAndSubstitutedModel} from '../models/modeling/model';
import { Injectable } from '@angular/core';
import { OEHttpClient } from '../core';
import {map} from 'rxjs/operators';
import { IRebalanceSleeveStrategy } from '../models/sleeve-strategy';
import { Observable } from 'rxjs';
import { IModelTypeFilter } from '../models/rebalance';
import { HiddenLeversRiskProfileDto } from '../models/modeling/hiddenLeversRiskProfile';
import {
  IHiddenLeversUserStatus,
  IStressTestResult,
  IStressTestScenario
} from '../models/modeling/hiddenLeversStressTest';


@Injectable({providedIn: 'root'})
export class ModelService {

  private _modelEndPoint = 'v1/modeling/models';
  private _modelEndPointV2 = 'v2/model';
  private _modelsEndPointV2 = 'v2/modeling/models';
  private _portfolioEndPointV2 = 'v2/portfolio/portfolios';
  private modelDashboardEndPoint = 'v1/dashboard/model/summary';
  private _sleevesEndPointV2 = 'v2/portfolio/sleeves';
  private _modelSimpleListEndPoint = 'v1/modeling/models/simple/list';
  private _orionConnectEndPointV2 = 'v2/orionconnect';

  constructor(private _httpClient: OEHttpClient) { }
  /************************************** GETs*********************************************/

  /**Create NEW Model for viewstructure*/
  getNewModel() {
    return this._httpClient.getData(`${this._modelEndPoint}/modelTypes`);
  }

  /** Get Sub Models Types */
  getSubModel(id: number) {
    return this._httpClient.getData(`${this._modelEndPoint}/submodels?modelType=${id}`);
  }

  getSubModelUsageReport(): Observable<Blob> {
    return this._httpClient.downloadFile(`${this._modelEndPoint}/report/submodels/usage`, {});
  }

  /**Get team Model data for auto complete */
  getModelSearch(searchString: string) {
    return this._httpClient.getData(`${this._modelEndPoint}?search=${encodeURIComponent(searchString)}`);
  }

  /** get Sleeved accounts for autocomplete */
  searchModelSleeveAccounts(searchString: string) {
    return this._httpClient.getData(`${this._modelEndPoint}?search=${searchString}`);
  }

  getModelData() {
    return this._httpClient.getData(`${this._modelEndPoint}`);
  }

  getModelDetail(id) {
    return this._httpClient.getData(`${this._modelEndPoint}/${id}`);
  }

  getOriginalAndSubstitutedModelDetailsByPortfolioId(portfolioId: number): Observable<IOriginalAndSubstitutedModel> {
    return this._httpClient.getData(`${this._modelEndPoint}/modelDetails/${portfolioId}`);
  }

  getModelRiskProfile(modelId: number, duration: number): Observable<HiddenLeversRiskProfileDto>{
    return this._httpClient.getData(`${this._modelEndPointV2}/${modelId}/RiskProfile/${duration}`);
  }

  getHiddenLeversUserStatus(email: string): Observable<IHiddenLeversUserStatus>{
    return this._httpClient.getData(`${this._modelsEndPointV2}/UserStatus?email=${email}`);
  }

  getStressTestScenarios(): Observable<IStressTestScenario[]>{
    return this._httpClient.getData(`${this._modelsEndPointV2}/StressTestScenarios`);
  }

  stressTestModel(modelId: number, scenarioIds: number[]): Observable<IStressTestResult[]>{
    return this._httpClient.postData(`${this._modelsEndPointV2}/StressTestResults`,
      {
        modelId: modelId,
        scenarioIds: scenarioIds
      });
  }

  /** Get pending model details by its Id */
  getPendingModelDetail(modelId: number) {
    return this._httpClient.getData(`${this._modelEndPoint}/${modelId}/pending`);
  }

  /**get Models dashboard summary*/
  getModelDashboardData() {
    return this._httpClient.getData(this.modelDashboardEndPoint);
  }

  /** To get model status */
  getModelStatus() {
    return this._httpClient.getData(`${this._modelEndPoint}/modelStatus`);
  }

  /** To get model status */
  getModelFilters() {
    return this._httpClient.getData(`${this._modelEndPoint}/filterTypes`);
  }

  getModelById(modelId: number) {
    return this._httpClient.getData(`${this._modelEndPointV2}/GetAllModels?modelId=${modelId}`);
  }

  /** Get all models list based on status excluding self directed */
  getModels(modelTypeId: number = 0, isSma: boolean = undefined) {
    // unassigned models - TODO: dynamic based on value from ModelTypes - able to get actual name?
    if (+modelTypeId === 5) {
      return this.getUnassignedAccounts();
    } else {
      return (+modelTypeId === 0)
        ? this._httpClient.getData(`${this._modelEndPointV2}/GetAllModels?isSma=${isSma ? isSma : ''}`)
        : this._httpClient.getData(`${this._modelEndPointV2}/GetAllModels?filter=${modelTypeId}&isSma=${isSma ? isSma : ''}`);
    }
  }

  /** get self directed model by accountId */
  getSelfDirectedModelDataByAccountId(accountId: number) {
    return this._httpClient.getData(`${this._modelEndPointV2}/GetAllModels?includeSelfDirected=2&selfDirectedAccountId=${accountId}`);
  }

  /** get all models including self directed */
  getModelsIncludingSelfDirected(includeSelfDirected: Boolean) {
    if (includeSelfDirected) {
      return this._httpClient.getData(`${this._modelEndPointV2}/GetAllModels?includeSelfDirected=1`);
    } else {
      return this._httpClient.getData(`${this._modelEndPointV2}/GetAllModels`);
    }
  }

  /** get only self directed models */
  getOnlySelfDirectedModels() {
    return this._httpClient.getData(`${this._modelEndPointV2}/GetAllModels?includeSelfDirected=2`);
  }

  /** get all unassigned models */
  getUnassignedAccounts() {
    return this._httpClient.getData(`${this._modelEndPointV2}/GetAllModels?includeSelfDirected=1&includeUnassignedAccounts=1`);
  }

  /** Get model portfolios based on model Id */
  getModelPortfolios(modelId: number) {
    return this._httpClient.getData(`${this._modelEndPoint}/${modelId}/portfolios`);
  }

  /** Get model portfolios based on model Id */
  getModelTeams(modelId: number, isEditModel: boolean = false) {
    return this._httpClient.getData(`${this._modelEndPoint}/${modelId}/teams?isEditModel=${isEditModel}`);
  }

  /** Get model portfolios based on model Id */
  getModelSecurityTypes(modelId: number) {
    return this._httpClient.getData(`${this._modelEndPoint}/${modelId}/securitytypes`);
  }

  /** get Models - Get All Model Management Styles */
  getModelManagementStyles() {
    return this._httpClient.getData(`${this._modelEndPoint}/managementStyles`);
  }

  geSubModelsByType(modelTypeId: number) {
    return this._httpClient.getData(`${this._modelEndPoint}/submodels?modelType=${modelTypeId}`);
  }

  /** Gets Allocations for selected Portfolio associated with a model */
  getModelPortfolioAllocation(portfolioId: number) {
    return this._httpClient.getData(`${this._portfolioEndPointV2}/${portfolioId}/allocations`);
  }

  /** Gets Target Allocations % for current model */
  getModelAllocation(modelId: number) {
    return this._httpClient.getData(`${this._modelEndPoint}/${modelId}/allocations`);
  }

  /** Get All Waiting For Approval Portfolios */
  getApprovalPortfolioslistOfModels(modelId: number) {
    return this._httpClient.getData(`${this._modelEndPoint}/${modelId}/portfolios/pending`);
  }

  /** Get All sleeved accounts for given Model */
  getSleevedAccountsForModel(modelId: number) {
    return this._httpClient.getData(`${this._modelEndPoint}/${modelId}/sleeves`);
  }

  /** Get Model Templates by required format */
  getModelTemplateByFormat(formatType) {
    return this._httpClient.getData(`${this._modelEndPoint}/upload/templates?format=${formatType}`);
  }

  /** Get Model Template */
  getModelTemplate() {
    return this._httpClient.getData(`${this._modelEndPoint}/upload/templates`);
  }

  downloadfile(type: string) {
    const headers = new Headers();
    headers.append('responseType', 'arraybuffer');
    return this._httpClient.getData(`${this._modelEndPoint}/upload/templates?format=${type}`)
      .pipe(
        map(res => new Blob([res], { type: 'application/vnd.ms-excel' }))
      );
  }

  /** Checks whether model can be rebalaced or not */
  canRebalanceModel(modelId: number) {
    return this._httpClient.getData(`${this._modelEndPoint}/${modelId}/canRebalance`);
  }

  /** Get The namespace compare  */

  /** Get Sub Models Types */
  validateModelNamespace(modelName: string, modelNamespace: string, isAddModel = 0) {
    return this._httpClient.getData(`${this._modelEndPoint}?name=${encodeURIComponent(modelName)}&nameSpace=${modelNamespace}&isAddModel=${isAddModel}`);
  }

  validateSubModelNamespace(submodelName: string, modelNamespace: string) {
    return this._httpClient.getData(`${this._modelEndPoint}/submodels?name=${submodelName}&nameSpace=${modelNamespace}`);
  }

  validateSubModelNamespaceExcludingLevel(submodelName: string, modelNamespace: string, exclude: string) {
    return this._httpClient.getData(`${this._modelEndPoint}/submodels?name=${submodelName}&nameSpace=${modelNamespace}&exclude=${exclude}`);
  }

  getSubModelDetailById(submodelid) {
    return this._httpClient.getData(`${this._modelEndPoint}/submodels/${submodelid}`);
  }

  /** To get model nodes based modelId */
  getModelNodes(modelId: number) {
    return this._httpClient.getData(`${this._modelEndPoint}/${modelId}/Model/nodes`);
  }

  /************************************** GETs*********************************************/

  /************************************** POSTs *********************************************/

  addModel(modelsave: IModelAdd) {
    return this._httpClient.postData(this._modelEndPoint, modelsave);
  }

  addSubModel(submodelsave: ICreateNewModel) {
    return this._httpClient.postData(`${this._modelEndPoint}/submodels`, submodelsave);
  }

  /** assign portfolio/s to model */
  assignPortfolioToModel(assignPortfolio: IAssignPortfolioToModel, modelId: number) {
    return this._httpClient.postData(`${this._modelEndPoint}/${modelId}/Portfolios`, assignPortfolio);
  }

  /** assign sleeve/s to model */
  assignSleeveToModel(assignSleeve: IAssignSleeveToModel, modelId: number) {
    return this._httpClient.postData(`${this._modelEndPoint}/${modelId}/sleeves`, assignSleeve);
  }

  /** Copy model */
  copyModel(modelId: number, copiedModel) {
    return this._httpClient.postData(`${this._modelEndPoint}/${modelId}/copy`, copiedModel);
  }

  copySubModel(subModelId: number, copiedSubModel) {
    return this._httpClient.postData(`${this._modelEndPoint}/submodels/${subModelId}/copy`, copiedSubModel);
  }

  /** Save ModelDeatils  */
  savemodeldetails(modelId, modelupdateData) {
    return this._httpClient.postData(`${this._modelEndPoint}/${modelId}/modelDetail/`, modelupdateData);
  }

  saveSMAModel(modelData){
    return this._httpClient.postData(`${this._modelEndPointV2}/SMA`, modelData);
  }

  /************************************** POSTs *********************************************/

  /************************************** PUTs/UPDATEs *********************************************/
  updateSubmodelfavoritestatus(subModelId, favoriteStatus) {
    return this._httpClient.updateData(`${this._modelEndPoint}/submodels/favorites/${subModelId}`, { isFavorite: favoriteStatus });
  }

  updateSubmodel(subModelId, name, namespace) {
    const data = {
      name: name,
      nameSpace: namespace
    };
    return this._httpClient.updateData(`${this._modelEndPoint}/submodels/${subModelId}`, data);
  }

  // OE-4000 Added modelTypeId while updating submodel
  updateSubmodelName(subModelId, name, namespace, modelTypeId) {
    const data = {
      name: name,
      nameSpace: namespace,
      modelTypeId: modelTypeId
    };

    return this._httpClient.updateData(`${this._modelEndPoint}/submodels/${subModelId}`, data);
  }

  updateModel(model: IModelAdd) {
    return this._httpClient.updateData(`${this._modelEndPoint}/${model.id}`, model);
  }

  /** Approve or Reject Portfolio/s from Model based on user privilege */
  approveOrRejectModelPortfolio(modelId: number, actionStatus: string, modelPortfolioApproval: any) {
    return this._httpClient.updateData(`${this._modelEndPoint}/${modelId}/portfolios/${actionStatus}`, modelPortfolioApproval);
  }

  /** Approve or reject Model which is waiting for Approval */
  approveOrRejectPendingModel(modelId: number, actionStatus: string, modelPendingApproval: any) {
    return this._httpClient.updateData(`${this._modelEndPoint}/${modelId}/pending/${actionStatus}`, modelPendingApproval);
  }

  /** Update ModelDeatils */
  updatemodeldetails(modelId, modelupdateData) {
    return this._httpClient.updateData(`${this._modelEndPoint}/${modelId}/modelDetail/`, modelupdateData);
  }

  /************************************** PUTs/UPDATEs *********************************************/

  /************************************** DELETEs *********************************************/

  /** Models - Can Delete Model */
  /** https://baseurl/v1/modeling/models/:id/can */
  canDeleteModel(modelId: number) {
    return this._httpClient.getData(`${this._modelEndPoint}/${modelId}/canDelete`);
  }

  /** Delete specific Model */
  deleteModel(modelId: number) {
    return this._httpClient.deleteData(`${this._modelEndPoint}/${modelId}`);
  }

  /** This will unassign the selected portfolio from Model */
  unAssignPortfolioFromModel(modelId: number, portfolioId: number) {
    return this._httpClient.deleteData(`${this._modelEndPoint}/${modelId}/Portfolios/${portfolioId}`);
  }

  // can Delete sub-Model
  canDeleteSubModel(subModelId, modelId, modelDetailId) {
    return this._httpClient.getData(`${this._modelEndPoint}/submodels/${subModelId}/canDelete?modelId=${modelId}&modelDetailId=${modelDetailId}`);
  }
  // Delete the Sub-Model
  deleteSubModel(subModelId, modelId) {
    return this._httpClient.deleteData(`${this._modelEndPoint}/submodels/${subModelId}?modelId=${modelId}`);
  }

  // can Delete sub model without model

  canDeleteSubModelWithoutModel(subModelId) {
    return this._httpClient.getData(`${this._modelEndPoint}/submodels/${subModelId}/canDelete`);
  }

  // Delete the Sub-Model without model
  deleteSubModelWithoutModel(subModelId) {
    return this._httpClient.deleteData(`${this._modelEndPoint}/submodels/${subModelId}`);
  }

  // Delete the Substitued Model
  deleteSubstitutedModel(details) {
    return this._httpClient.updateData(`${this._modelEndPoint}/deleteSubstitutedModel`, details);
  }

  /************************************** DELETEs *********************************************/

  /************************************** UPLOADs *********************************************/

  /** Upload selected file */
  uploadModelTemplate(file: IModelExcelImport) {
    const formData: any = new FormData();
    formData.append('file', file.file);
    return this._httpClient.uploadFile(`${this._modelEndPoint}/upload`, formData);
  }

  /************************************** UPLOADs *********************************************/

  patchModelNameNameSpace(modelId, modelName, modelNameSpace) {
    const data = [
      { op: 'replace', path: '/name', value: modelName },
      { op: 'replace', path: '/nameSpace', value: modelNameSpace }
    ];
    const patchdata = {
      data: data
    };
    return this._httpClient.patchData(`${this._modelEndPoint}/${modelId}`, patchdata);
  }

  modelHistoryAuditLog(id, toDate, fromDate) {
    return this._httpClient.getData(`auditlog/model/${id}?toDate=${toDate}&fromDate=${fromDate}`);
  }
  modelStructureAuditLog(id, toDate, fromDate) {
    return this._httpClient.getData(`auditlog/modeldetail/${id}?toDate=${toDate}&fromDate=${fromDate}`);
  }

  updateSubModelDetails(subModelDetail: ISubModel) {
    return this._httpClient.postData(`${this._modelEndPoint}/submodels/submodeldetail`, subModelDetail);
  }
  deleteSubModelInBulk(submodels, modelId) {
    return this._httpClient.updateData(`${this._modelEndPoint}/submodels?modelId=${modelId}`, submodels);
  }

  /*Get Model Allocations for portfolio based on sub model type */

  getModelPortfolioAllocationsBasedOnType(id, type) {
    return this._httpClient.getData(`${this._portfolioEndPointV2}/${id}/allocations?assetType=${type}`);
  }

  /*getSleeve Allocations for sleeve based on sub model type */

  getModelSleeveAllocationBasedOnType(id, type) {
    return this._httpClient.getData(`${this._sleevesEndPointV2}/${id}/allocations?assetType=${type}`);
  }

  /*Tactical Rebalance For Model */

  tacticalRebalanceForModel(modelDetails) {
    return this._httpClient.postData('rebalancer/rebalance', modelDetails);
  }

  /*Get Model portfolio Allocations in single api call for all the sub model types */

  getModelPortfolioAllocations(id) {
    return this._httpClient.getData(`${this._portfolioEndPointV2}/${id}/allocations`);
  }

  getModelSleeveAllocations(id) {
    return this._httpClient.getData(`${this._sleevesEndPointV2}/${id}/allocations`);
  }

  /**
   * Get Simple model list based on list of model ids
   * @param selectedIds
   */
  getModelsSimpleListByIds(selectedIds: number[]) {
    return this._httpClient.postData(this._modelSimpleListEndPoint, { ids: selectedIds });
  }

  /*Getting approval sleeves for model*/
  getApprovalSleevelistOfModels(id: number) {
    return this._httpClient.getData(`${this._modelEndPoint}/${id}/sleeveaccount/pending`);
  }

  /*Approve or reject the sleeve */
  approveOrRejectModelSleeve(modelId: number, actionStatus: string, sleeveIds) {
    return this._httpClient.updateData(`${this._modelEndPoint}/${modelId}/sleevedaccount/${actionStatus}`, sleeveIds);
  }

  getModelDetailBySubModelId(submodelid) {
    return this._httpClient.getData(`${this._modelEndPoint}/list/${submodelid}`);
  }

  /** Get Model and modelDetailId */
  getModelAndModelDetailId(modelDetailIds: number[]) {
    return this._httpClient.postData(`${this._modelEndPoint}/modelsByModelDetails`, {modelDetailIds});
  }
  /** Get Models Details for Export to excel */
  getModelsExportData(modelIds) {
    return this._httpClient.downloadFile(`${this._modelEndPoint}/export`, { modelIds: modelIds });
  }
  /*removed node details*/
  getPortfoliosOnRemove(modelId, deletdModelDetailId, modelDetailIds) {
    const deletedNodeData = {
      allModelDetailIds: modelDetailIds,
      modelId: modelId,
      deletedNodeModelDetailID: deletdModelDetailId
    };
    return this._httpClient.postData(`${this._modelEndPoint}/getImpactedPortfolioAccounts`, deletedNodeData);
  }
  /* Selected node SMA weightings */
  getModelNodeSMAWeightings(modelId, modelElementId) {
    return this._httpClient.getData(`${this._modelEndPoint}/${modelId}/smaweightings/${modelElementId}`);
  }

  /** Get Model by elementId */
  getModelByElementId(modelDetailId, modelId) {
    return this._httpClient.getData(`${this._modelEndPoint}/modeElement/${modelDetailId}?modelId=${modelId}`);
  }

  /** Get Sub Models by type */
  getAllSubModelByType(modelTypeId: number) {
    return this._httpClient.getData(`${this._modelEndPoint}/allSubModel?modelType=${modelTypeId}`);
  }

  /** Get Sub Model for model type Category, Class, Subclass */
  getSubModels(excludeCommunityModels: boolean) {
    return this._httpClient.getData(`${this._modelEndPoint}/getSubmodels?excludeCommunityModels=${excludeCommunityModels}`);
  }

  /** Get Teams of Sub Model */
  getTeamsOfSubModel(subModelId: number) {
    return this._httpClient.getData(`${this._modelEndPoint}/submodel/${subModelId}/teams`);
  }

  retryUpdateOtherModel(retryModelId: number) {
    return this._httpClient.postData(`${this._modelEndPoint}/updateOtherModel/retryProcess`, {isRetryUpdateOtherModel: true, retryModelId: retryModelId});
  }

  getSecuritiesOfSubModel(filter: any) {
    return this._httpClient.postData(`${this._modelEndPoint}/submodels/securities`, { filter });
  }

  getProductClasses(){
    return this._httpClient.getData(`${this._orionConnectEndPointV2}/GetProductClasses`);
  }
  getRiskCategories(){
    return this._httpClient.getData(`${this._orionConnectEndPointV2}/GetRiskCategories`);
  }
  getAssignedModels(filter: IModelTypeFilter): Observable<IRebalanceSleeveStrategy[]> {
    return this._httpClient.postData('v2/modeling/models/GetAssignedModels', filter);
  }

  getModelTypes(){
    return this._httpClient.getData(`${this._modelEndPointV2}/GetModelTypes`);
  }
  getSyncModelsToOC(modelId: number){
    return this._httpClient.getData(`${this._modelEndPointV2}/GetModelSyncToOCFirms?modelId=${modelId}`)
  }
}
