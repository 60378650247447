import { Injectable } from '@angular/core';
import { OEHttpClient } from '../core';
import {
  IQuickTradeData,
  IQuickTradeBlockData,
  IPatchTradeBlock,
  IPatchTradeOrder,
  IValidateTrades,
  AutoAllocateType,
  IBlockOrderAllocation,
  ISplitBlockRequest,
  ITradeBlock, ITradeOrderDynamic, ICreateTradeAwayBlock, ITradeOrderDynamicRequestOptions, IMassEditBlock
} from '../models/tom';
import { Observable } from 'rxjs';
import { ITradeOrderMessage } from '../models/tom';
import { IDynamicGridViewState } from '../shared/gridextensions/dynamic-column-filter.component';

@Injectable({
  providedIn: 'root'
})
export class TomService {

  // V1 API endpoints
  private _tomEndPoint = 'v1/tradeorder/trades';
  private _tomBlocksEndPoint = 'v1/tradeorder/blocks';
  private _tomUnAllocatedBlocksEndPoint = 'v1/tradeorder/blocks/unallocated';
  private _tradeFiles = 'v1/tradeorder/tradefiles';
  private _accountEndPoint = 'v1/account/accounts';
  private _saveFixedIncomeTradEndPoint = 'v1/tradeorder/fixedIncome/uploadTrades';
  private _tomDeletedEndPoint = 'v1/tradeorder/deletedTrades';
  private _tomClosedOrders = 'v1/tradeorder/closedTrades';
  private _tomAuditLogEndPoint = 'v1/auditlog/tradeOrders';
  private _tomBlockAuditLogEndPoint = 'v1/auditlog/tradeBlocks';
  private _tomInstances = 'v1/tradeOrder/instances';
  private _reverseSynchJob = 'v1/reversesynch';
  private _fixedIncomeImportEndPoint = 'v1/tradeorder/fixedIncome/uploadfile';
  private _tomReports = 'v1/report/trades';

  // V2 API endpoints
  private _tradesCountEndpoint = 'v2/tradeorder/trades/count';
  private _tradingEndpoint = 'v2/trading';
  private _tradingInstanceEndpoint = `${this._tradingEndpoint}/Instance`;
  private _tradingBlocksEndpoint = `${this._tradingEndpoint}/blocks`;
  private _tradeOrderEndpoint = 'v2/tradeorder/trades';
  private _tradeOrderMessagesEndpoint = 'v2/TradeOrder/Trades/TradeOrderMessages';
  private _tradeImportEndPoint = 'v2/tradeorder/uploadfile';
  private _saveTradeEndPoint = 'v2/tradeorder/uploadTrades';
  private _tradeLogDetailsV2 = 'v2/Trading/TradeLogById';
  private _autoAllocateBlockEndpoint = `${this._tradingBlocksEndpoint}/Action/AllocateAutoAdjust`;
  private _tradeInstanceByDateRange = `${this._tradingInstanceEndpoint}/GetByDateRange`;
  private _tradeInstanceById = `${this._tradingInstanceEndpoint}/GetByInstanceID`;
  private _tradeOrderDynamicGridEndpoint = 'v2/TradeOrder/Trades/list';
  private _tradeAwayBlockEndpoint = 'v2/Trading/Blocks/Action';
  private _realTimePrice = 'v2/TradeOrder/Trades/RealTimePrices';
  private _realTimePriceDetail = 'v2/TradeOrder/Trades/RealTimePrices/Detail';

  constructor(
    private _httpClient: OEHttpClient) {
  }

  getTradeOrders(filterId: number = 0, tradeInstanceId?: number) {
    return (filterId === 0)
      ? (tradeInstanceId ? this._httpClient.getData(`${this._tomEndPoint}?tradeInstanceId=${tradeInstanceId}`)
        : this._httpClient.getData(this._tomEndPoint))
      : this._httpClient.getData(`${this._tomEndPoint}?filterId=${filterId}`);
  }

  getTradeOrdersDynamic(state: IDynamicGridViewState, options?: ITradeOrderDynamicRequestOptions): Observable<ITradeOrderDynamic[]> {
    let url = this._tradeOrderDynamicGridEndpoint;
    const params = [];
    if (!!options?.tradeId) {
      params.push(`tradeId=${options.tradeId}`);
    }
    if (!!options?.batchId) {
      params.push(`batchId=${options.batchId}`);
    }
    if (!!options?.endDate) {
      params.push(`endDate=${options.endDate}`);
    }
    if (!!options?.teamId) {
      params.push(`teamId=${options.teamId}`);
    }
    if (!!options?.accountIds) {
      params.push(`accountIds=${options.accountIds}`);
    }
    if (!!options?.blockId) {
      params.push(`blockId=${options.blockId}`);
    }
    if (!!options?.filterId) {
      params.push(`filterId=${options.filterId}`);
    }
    if (!!options?.instanceId) {
      params.push(`instanceId=${options.instanceId}`);
    }
    if (!!options?.limit) {
      params.push(`limit=${options.limit}`);
    }
    if (!!options?.offset) {
      params.push(`offset=${options.offset}`);
    }
    if (!!options?.portfolioId) {
      params.push(`portfolioId=${options.portfolioId}`);
    }
    if (!!options?.startDate) {
      params.push(`startDate=${options.startDate}`);
    }
    if (!!options?.tradeFilterId) {
      params.push(`tradeFilterId=${options.tradeFilterId}`);
    }
    if (params.length) {
      url = `${url}?${params.join('&')}`;
    }
    return this._httpClient.postData(url, state);
  }

  getTradeBlocks() {
    return this._httpClient.getData(this._tomBlocksEndPoint);
  }

  createTradeAwayBlock(createTradeAwayBlockData: ICreateTradeAwayBlock): Observable<void> {
    return this._httpClient.postData(`${this._tradeAwayBlockEndpoint}/CreateTradeAwayBlock`, createTradeAwayBlockData);
  }

  deleteTradeAwayBlock(tradeAwayBlockIds: number[]): Observable<void> {
    return this._httpClient.postData(`${this._tradeAwayBlockEndpoint}/DeleteTradeAwayBlock`, tradeAwayBlockIds);
  }

  getTradeBlocksV2() {
    return this._httpClient.getData(`${this._tradingBlocksEndpoint}`);
  }

  getTradeOrderBlocks(ids: string) {
    return this._httpClient.getData(`${this._tomBlocksEndPoint}?tradeIds=${ids}&awaitingAcceptance=true`);
  }

  getBlockTradeOrders(blockId: number) {
    return this._httpClient.getData(`${this._tomEndPoint}?blockId=${blockId}`);
  }

  getTradeOrderCount() {
    return this._httpClient.getData(`${this._tomEndPoint}/count`);
  }

  getTradeBlocksCount() {
    return this._httpClient.getData(`${this._tomBlocksEndPoint}/count`);
  }

  updateOrder(id, order: IPatchTradeOrder) {
    return this._httpClient.patchData(`${this._tomEndPoint}/${id}`, order);
  }

  updateBlock(id, block: IPatchTradeBlock) {
    return this._httpClient.patchData(`${this._tomBlocksEndPoint}/${id}`, block);
  }

  deleteTradeOrders() {
    return this._httpClient.deleteData(`${this._tomEndPoint}/deleteAll`);
  }

  deleteTradeBlocks() {
    return this._httpClient.deleteData(`${this._tomBlocksEndPoint}/deleteAll`);
  }

  // deletes selected tradeorders
  deleteTradeOrder(data: any) {
    return this._httpClient.updateData(`${this._tomEndPoint}/action/delete`, data);
  }

  // delete selected blocks -fix for OEF-5476
  deleteSelectedTradeBlock(data: any) {
    return this._httpClient.updateData(`${this._tomBlocksEndPoint}/action/delete`, data);
  }

  deleteTradeBlock(id: number) {
    return this._httpClient.deleteData(`${this._tomBlocksEndPoint}/${id}`);
  }

  deleteZeroQuantityTradeOrders(data: any) {
    return this._httpClient.updateData(`${this._tomEndPoint}/action/deleteZeroQuantity`, data);
  }

  deleteAllZeroQuantityTradeOrders() {
    return this._httpClient.deleteData(`${this._tomEndPoint}/action/deleteZeroQuantity`);
  }

  executeAllTradeBlocks(ocFirmId: number) {
    return this._httpClient.updateData(`${this._tomBlocksEndPoint}/action/executeBlocks`, {blockIds: [], orionConnectFirmId: ocFirmId});
  }

  sendSftpBlock(data: any) {
    return this._httpClient.updateData(`${this._tomBlocksEndPoint}/sftpTransfer/transfer`, data);
  }

  executeSelectedSftpTradeBlocks(data: any) {
    return this._httpClient.updateData(`${this._tomBlocksEndPoint}/action/executeBlocks`, data);
  }

  markAsDoneForDay() {
    return this._httpClient.updateData(`${this._tomBlocksEndPoint}/action/markStatusDoneForDay`, {});
  }

  executeSelectedTradeBlocks(data: any) {
    return this._httpClient.updateData(`${this._tomBlocksEndPoint}/action/executeBlocks`, data);
  }

  cancelSelectedTradeBlocks(data: any) {
    return this._httpClient.updateData(`${this._tomBlocksEndPoint}/action/cancelBlocks`, data);
  }

  enableTradeOrders(data: any) {
    return this._httpClient.updateData(`${this._tomEndPoint}/action/enable`, data);
  }

  disableTradeOrders(data: any) {
    return this._httpClient.updateData(`${this._tomEndPoint}/action/disable`, data);
  }

  processTradeOrders(data: any) {
    return this._httpClient.updateData(`${this._tomEndPoint}/action/processTrades`, data);
  }

  // OE-2818 reset Awaiting Acceptance Test
  resetTradeOrders(data: any) {
    return this._httpClient.patchData(`${this._tomEndPoint}/action/resetTrades`, data);
  }

  /** To Get TradeFiles List*/
  gettradeFiles(date: Date) {
    return this._httpClient.getData(`${this._tradeFiles}?fromDate=${date}`);
  }

  /** Update TradeFile status*/
  updateTradeFile(id: number) {
    return this._httpClient.updateData(`${this._tradeFiles}/${id}/action/sent`, {});
  }

  /** Delete Trade File */
  deleteTradeFile(id: number) {
    return this._httpClient.deleteData(`${this._tradeFiles}/${id}`);
  }

  /** Count of the TradeFiles */
  getTradeFilesCount() {
    return this._httpClient.getData(`${this._tradeFiles}/count`);
  }

  /** to get all left nav count OE-1321 */
  getTradesCount() {
    return this._httpClient.getData(this._tradesCountEndpoint);
  }

  getAwaitingAcceptenceTrades() {
    return this._httpClient.getData(`${this._tomEndPoint}/awaitingAcceptance`);
  }

  getDurations() {
    return this._httpClient.getData(`${this._tomEndPoint}/durations`);
  }

  getOrderTypes() {
    return this._httpClient.getData(`${this._tomEndPoint}/orderTypes`);
  }

  getQualifiers() {
    return this._httpClient.getData(`${this._tomEndPoint}/qualifiers`);
  }

  getTradeActions() {
    return this._httpClient.getData(`${this._tomEndPoint}/tradeActions`);
  }

  getCfiCodes() {
    return this._httpClient.getData(`${this._tomBlocksEndPoint}/cfiCodes`);
  }

  getOrderCapacities() {
    return this._httpClient.getData(`${this._tomBlocksEndPoint}/orderCapacities`);
  }

  getPositionEffects() {
    return this._httpClient.getData(`${this._tomBlocksEndPoint}/positionEffects`);
  }

  getApprovalStatus(tradeIds: string = '', portfolioId: number = null) {
    if (portfolioId !== null) {
      return this._httpClient.getData(`${this._tomEndPoint}/tradeApprovalStatus?portfolioId=${portfolioId}`);
    } else {
      tradeIds = (!tradeIds) ? '' : `?tradeIds=${tradeIds}`;
      return this._httpClient.getData(`${this._tomEndPoint}/tradeApprovalStatus${tradeIds}`);
    }
  }

  setApprovalStatus(id: number, data: any) {
    return this._httpClient.updateData(`${this._tomEndPoint}/action/setApprovalStatus/${id}`, data);
  }

  getTradeDetail(id: number) {
    return this._httpClient.getData(`${this._tomEndPoint}/${id}`);
  }

  getTradeBlockDetail(id: number) {
    return this._httpClient.getData(`${this._tomBlocksEndPoint}/${id}`);
  }

  /**
   * Model Tolerance API
   */
  getModelTolerance(levelName: string, portfolioId: number, accountId: string, isSleevedPorfolio: boolean, selectedLevelId: number): Observable<any> {
    const params = `?assetType=${levelName}&isSleevePortfolio=${isSleevedPorfolio}&levelId=${selectedLevelId}`;
    return this._httpClient.getData(`portfolio/portfolios/${portfolioId}/modelTolerance/${accountId}${params}`);
  }

  getModelMACTolerance(portfolioId: number, accountId: string, isSleevedPortfolio: boolean): Observable<any> {
    const params = isSleevedPortfolio ? '?accountType=Sleeve' : '?accountType=Normal';
    return this._httpClient.getData(`portfolio/portfolios/${portfolioId}/ModelMACTolerance/${accountId}${params}`);
  }

  /**
   * Model Tolerance/Analysis API
   * Get model levels by model id
   */
  getModelLevels(modelId: number) {
    return this._httpClient.getData(`v2/modeling/models/${modelId}/levels`);
  }

  /**
   * Model Analysis API
   * Get list of models belongs to trades loaded in the grid
   */
  getModelsByTrades() {
    return this._httpClient.getData(`${this._tomEndPoint}/model/simple`);
  }

  /**
   * Model Analysis API
   * Get model summary by model id and parameters
   */
  getModelSummary(modelId: number, costBasis: boolean, tradeBlock: boolean, excludeAsset: boolean) {
    const params = [`isIncludeCostBasis=${costBasis ? 1 : 0}`];
    params.push(`isIncludeTradeBlockAccount=${tradeBlock ? 1 : 0}`);
    params.push(`isExcludeAsset=${excludeAsset ? 1 : 0}`);
    return this._httpClient.getData(`v2/modeling/models/${modelId}/modelAnalysis/modelAggregate?${params.join('&')}`);
  }

  /**
   * Model Analysis API
   * Get selected level data by model id and parameters
   */
  getLevelDataByModelId(levelName: string, modelId: number, costBasis: boolean, tradeBlock: boolean, excludeAsset: boolean) {
    const params = [`&isIncludeCostBasis=${costBasis ? 1 : 0}`];
    params.push(`isIncludeTradeBlockAccount=${tradeBlock ? 1 : 0}`);
    params.push(`isExcludeAsset=${excludeAsset ? 1 : 0}`);
    return this._httpClient.getData(`v2/modeling/models/${modelId}/modelAnalysis?assetType=${levelName}${params.join('&')}`);
  }

  /**
   * Model Analysis API
   * Get portfolios with out of tolerance by model id and asset id
   */
  getPortfoliosByAssetId(levelName: string, modelId: number, assetId: number, modelElementId: number) {
    return this._httpClient.getData(`v2/portfolio/portfolios/${modelId}/outOfTolerance/${assetId}?assetType=${levelName}&modelElementId=${modelElementId}`);
  }

  /**
   * Model Analysis API
   * Get accounts with out of tolerance by model id and asset id
   */
  getAccountsByAssetId(levelName: string, modelId: number, assetId: number) {
    return this._httpClient.getData(`account/accounts/${modelId}/outOfTolerance/${assetId}?assetType=${levelName}`);
  }

  /** Quick Trade Api*/
  /** get action types while selecting account*/
  getAccountActions() {
    return this._httpClient.getData(`${this._tomEndPoint}/accountActions`);
  }

  /** get action types while selecting portfolio*/
  getPortfolioActions() {
    return this._httpClient.getData(`${this._tomEndPoint}/portfolioActions`);
  }

  /** get account details to populate account tab in quick trade*/
  getAccountDetails(id: number) {
    return this._httpClient.getData(`${this._accountEndPoint}/${id}`);

  }

  getPortfolioDetails(id: number) {
    return this._httpClient.getData(`${'portfolio/portfolios' + '/'}${id}`);

  }

  /** To save quick trade*/
  saveTrade_v1(quickTrade: IQuickTradeData) {
    return this._httpClient.postData(`${this._tomEndPoint}/`, quickTrade);
  }

  saveTrade(quickTrade: IValidateTrades) {
    return this._httpClient.postData(`${this._tradeOrderEndpoint}/`, quickTrade);
  }

  /** To save quick trade*/
  saveNewTradeBlock(quickTrade: IQuickTradeBlockData) {
    return this._httpClient.postData(`${this._tomBlocksEndPoint}/`, quickTrade);

  }

  /** To validate quick trade, api call needs to be changed*/
  validateTrade(quickTrade: IValidateTrades) {
    return this._httpClient.postData(`${this._tradeOrderEndpoint}/action/validate`, quickTrade);
  }

  /** get assest tab details for quicktrade-account */
  getAssetsforAccount(id: number) {
    return this._httpClient.getData(`${this._accountEndPoint}/${id}/holdings`);
  }

  /** get assest tab details for quicktrade-portfolio */
  getAssetsforPortfolio(id: number) {
    return this._httpClient.getData(`portfolio/portfolios/${id}/holdings`);
  }

  /** get price tab details for quicktrade */
  getPrice(id: number) {
    return this._httpClient.getData(`security/securities/price/${id}`);
  }

  //TODO: remove this when skip_quodd_10037 flag is killed
  /** Get real-time prices based on price source preference. */
  getRealTimePrices(symbols:  any[]) {
    return this._httpClient.postData(`${this._realTimePrice}`, symbols);
  }

  /** Get detailed real-time prices based on price source preference. */
  getRealTimePricesDetail(symbols: any[]) {
    return this._httpClient.postData(`${this._realTimePriceDetail}`, symbols);
  }

  /**Import Trade Order Excel */
  importTradeFile(file: any) {
    const formData: any = new FormData();
    formData.append('document', file);
    //  formData.append("isPending", isPending);
    return this._httpClient.uploadFile(this._tradeImportEndPoint, formData);
  }

  /**save valid uploaded Trades */
  saveUploadTrades(trades) {
    return this._httpClient.postData(this._saveTradeEndPoint, trades);
  }

  // update on grid cell edit
  UpdateClientDirected(id, order: any) {
    return this._httpClient.updateData(`${this._tomEndPoint}/${id}/action/clientDirected`, order);
  }

  // update on grid cell edit
  updateHoldUntil(id, order: any) {
    return this._httpClient.updateData(`${this._tomEndPoint}/${id}/action/holdUntil`, order);
  }

  // update on grid cell edit
  updateSettlementType(id, order: any) {
    return this._httpClient.updateData(`${this._tomEndPoint}/${id}/action/settlementType`, order);
  }

  // update on grid cell edit-OEMP 17
  updateOrderQty(id, order: any) {
    return this._httpClient.updateData(`${this._tomEndPoint}/${id}/action/quantity`, order);
  }

  // update on grid cell edit-OEMP 165
  updateCumQty(id, order: any) {
    return this._httpClient.updateData(`${this._tomBlocksEndPoint}/${id}/action/cumQty`, order);
  }

  // update on grid cell edit-OEMP 165
  updateAvgPrice(id, order: any) {
    return this._httpClient.updateData(`${this._tomBlocksEndPoint}/${id}/action/avgPrice`, order);
  }


  updateOrderStatus(id, order: any) {
    return this._httpClient.updateData(`${this._tomBlocksEndPoint}/${id}/action/orderStatus`, order);
  }


  updateAllocationStatus(id, order: any) {
    return this._httpClient.updateData(`${this._tomBlocksEndPoint}/${id}/action/allocationStatus`, order);
  }

  updateRejectReason(id, order: any) {
    return this._httpClient.updateData(`${this._tomBlocksEndPoint}/${id}/action/allocationRejectReason`, order);
  }


  // update on grid cell edit-OEMP 165
  getOrderStatus() {
    return this._httpClient.getData(`${this._tomEndPoint}/orderStatus`);
  }

  // update on grid cell edit-OEMP 165
  getAllocationStatus() {
    return this._httpClient.getData(`${this._tomEndPoint}/allocationStatus`);
  }

  // update on grid cell edit-OEMP 165
  getBlockRejectReasons() {
    return this._httpClient.getData(`${this._tomEndPoint}/getBlockRejectReasons`);
  }


  // get settlementType master data
  getSettlementTypes() {
    return this._httpClient.getData(`${this._tomEndPoint}/settlementTypes`);
  }

  // get tradeOrderMessages to display in message column of grid
  getTradeOrderMessages(id) {
    return this._httpClient.getData(`${this._tomEndPoint}/tradeOrderMessages/${id}`);
  }

  // get tradeBlockFixMessages to display in message column of grid
  getTradeBlockFixMessages(id) {
    return this._httpClient.getData(`${this._tradingBlocksEndpoint}/${id}/FixMessages`);
  }

  getOrdersForPortfolio(id) {
    return this._httpClient.getData(`${this._tomEndPoint}/${id}`);
  }

  /**TOM Allocation API's */

  GetAllUnAllocatedBlocks() {
    return this._httpClient.getData(this._tomUnAllocatedBlocksEndPoint);
  }

  getAllocationsCount() {
    return this._httpClient.getData(`${this._tomUnAllocatedBlocksEndPoint}/count`);
  }

  getFixMessagesbyId(blockId: any) {
    return this._httpClient.getData(`${this._tradingBlocksEndpoint}/${blockId}/FixMessages`);
  }

  getFeeCommissionDetails(blockId: any) {
    return this._httpClient.getData(`${this._tomUnAllocatedBlocksEndPoint}/${blockId}/fees`);
  }

  saveFeeCommissionDetails(blockId: any, data: any) {
    return this._httpClient.updateData(`${this._tomUnAllocatedBlocksEndPoint}/${blockId}/fees`, data);
  }

  getUnAllocatedTradeBlocks(blockId: any) {
    return this._httpClient.getData(`${this._tomUnAllocatedBlocksEndPoint}/${blockId}/trades`);
  }

  validateBlockOrders(type: string, ids: any) {
    return this._httpClient.postData(`${this._tomUnAllocatedBlocksEndPoint}/validate?type=${type}`, ids);
  }

  allocateOrders(ids: any) {
    if (ids && ids.id && ids.id.length) {
      return this._httpClient.postData(`${this._tomUnAllocatedBlocksEndPoint}/action/allocate`, ids);
    } else {
      return this._httpClient.postData(`${this._tomUnAllocatedBlocksEndPoint}/action/allocateAll`, undefined);
    }
  }

  allocateSftpBlockOrders(blockId: any, data: any, markComplete: boolean) {
    if (markComplete) {
      return this._httpClient.updateData(`${this._tomUnAllocatedBlocksEndPoint}/${blockId}/trades/action/sftp/allocate?markComplete=true`, data);
    } else {
      return this._httpClient.updateData(`${this._tomUnAllocatedBlocksEndPoint}/${blockId}/trades/action/sftp/allocate`, data);
    }
  }

  sendTdaTradeFile(id) {
    return this._httpClient.updateData(`${this._tradeFiles}/${id}/action/tdadirect`, {});
  }

  markComplete(ids: any) {
    if (ids && ids.id && ids.id.length) {
      return this._httpClient.postData(`${this._tomUnAllocatedBlocksEndPoint}/action/allocate?markComplete=true`, ids);
    } else {
      return this._httpClient.postData(`${this._tomUnAllocatedBlocksEndPoint}/action/allocateAll?markComplete=true`, undefined);
    }
  }

  CreateAllocationsByImport(blockId: any, file: any) {
    const trades: any = new FormData();
    trades.append('trades', file);
    return this._httpClient.uploadFile(`${this._tomUnAllocatedBlocksEndPoint}/${blockId}/newAllocation/import`, trades);
  }

  SaveImportedData(blockId: any, order: any) {
    return this._httpClient.postData(`${this._tomUnAllocatedBlocksEndPoint}/${blockId}/newAllocation`, order);
  }

  SaveNewAllocation(blockId: any, order: any) {
    return this._httpClient.postData(`${this._tomUnAllocatedBlocksEndPoint}/${blockId}/newAllocation`, order);
  }

  allocateBlockOrders(blockId: any, data: any, markComplete: boolean) {
    if (markComplete) {
      return this._httpClient.updateData(`${this._tomUnAllocatedBlocksEndPoint}/${blockId}/trades/action/allocate?markComplete=true`, data);
    } else {
      return this._httpClient.updateData(`${this._tomUnAllocatedBlocksEndPoint}/${blockId}/trades/action/allocate`, data);
    }
  }

  allocateMultipleBlockOrders(data: any, markComplete: boolean) {
    if (markComplete) {
      return this._httpClient.updateData(`${this._tomUnAllocatedBlocksEndPoint}/trades/action/allocate?markComplete=true`, data);
    } else {
      return this._httpClient.updateData(`${this._tomUnAllocatedBlocksEndPoint}/trades/action/allocate?markComplete=false`, data);
    }
  }

  saveBlockOrders(blockId: any, data: any) {
    return this._httpClient.updateData(`${this._tomUnAllocatedBlocksEndPoint}/${blockId}/trades/action/save`, data);
  }

  saveMatchingBlockOrders(data: any) {
    return this._httpClient.updateData(`${this._tomUnAllocatedBlocksEndPoint}/trades/action/save`, data);
  }

  createTradeFile(ids: any) {
    return this._httpClient.postData(`${this._tomUnAllocatedBlocksEndPoint}/action/processBlocks`, ids);
  }

  callReverseSynch(type, data: any) {
    return this._httpClient.postData(`${this._reverseSynchJob}/retryReverseSyncprocess?type=${type}`, data);
  }

  /**To get deleted Trades(TOM) data */
  getDeletedTrades(startDate: string, endDate: string) {
    return this._httpClient.getData(`${this._tomDeletedEndPoint}?startDate=${startDate}&endDate=${endDate}`);
  }

  /** gets all pending orders data */
  getAllPendingOrders() {
    return this._httpClient.getData(`${this._tomEndPoint}?isPending=true`);
  }

  /** To get pending orders by account id/ids */
  getPendingOrdersAccountIds(accountIds: string[]) {
    return this._httpClient.getData(`${this._tomEndPoint}?isPending=true&accountIds=${accountIds}`);
  }

  /** gets all pending blocks data */
  getAllPendingBlocks() {
    return this._httpClient.getData(`${this._tomBlocksEndPoint}?isPending=true`);
  }

  /** Get Fix message details for a block*/
  getPendingBlockFixMessages(id) {
    return this._httpClient.getData(`${this._tradingBlocksEndpoint}/${id}/FixMessages`);
  }

  /** Get pending orders count */
  getPendingOrderCount() {
    return this._httpClient.getData(`${this._tomEndPoint}/count?isPending=true`);
  }

  /* updates rejected trades  */
  rejectTrades(data: any) {
    return this._httpClient.updateData(`${this._tomEndPoint}/action/setRejected`, data);
  }

  getBlockPendingOrders(blockid) {
    return this._httpClient.getData(`${this._tomEndPoint}?isPending=true&blockId=${blockid}`);
  }

  getInstancesV2(startDate: string, endDate: string) {
    return this._httpClient.getData(`${this._tradeInstanceByDateRange}?startDate=${startDate}&endDate=${endDate}`);
  }

  getInstancesByIdV2(tradeInstanceId: number) {
    return this._httpClient.getData(`${this._tradeInstanceById}?tradeInstanceId=${tradeInstanceId}`);
  }

  /**
   * To Get Trade Log for TOM Instances
   */
  getTradeLogs(id: number) {
    return this._httpClient.getData(`${this._tomInstances}/${id}/tradeLogs`);
  }

  getTradeLogsByPortfolioId(portfolioId: number, startDate: string, endDate: string) {
    return this._httpClient.getData(`${this._tomInstances}/tradeLogs/${portfolioId}/instances?startDate=${startDate}&endDate=${endDate}`);
  }

  getTradeLogDetails(id: number) {
    return this._httpClient.getData(`${this._tradeLogDetailsV2}/${id}`);
  }

  /**get trade orders by Instance Id */
  getTradesByInstanceId(id: number) {
    return this._httpClient.getData(`${this._tomInstances}/${id}/trades?status=open`);
  }

  /**Get View Parameters for selected Record */
  getTradeParamenters(id: number) {
    return this._httpClient.getData(`${this._tomInstances}/${id}/tradeParameter`);
  }


  /** Enable and Disable for selected Records in Instances */
  markEnable(instancesIds: number[], action: boolean) {
    return this._httpClient.postData(`${this._tomInstances}/mark?isEnabled=${action}`, {instanceId: instancesIds});
  }

  /**delete Instances */
  deleteInstances(Id: number) {
    return this._httpClient.deleteData(`${this._tomInstances}/${Id}`);
  }

  /**View Open / Closed in Instances*/
  getViewStatus(id: number, action: string) {
    if (action === 'open') {
      return this._httpClient.getData(`${this._tomInstances}/${id}/trades?status=open`);
    } else {
      return this._httpClient.getData(`${this._tomInstances}/${id}/trades?status=closed`);
    }
  }

  /**Post Process in TOM Instances */
  processInstances(instancesIds: number[]) {
    return this._httpClient.postData(`${this._tomInstances}/action/processInstance`, {instanceIds: instancesIds});
  }

  /** Get closed orders count */
  getclosedOrdersCount() {
    return this._httpClient.getData(`${this._tomClosedOrders}/count`);
  }

  GetAllClosedTrades(startDate: string, endDate: string, blockId: number) {
    if (!blockId || isNaN(blockId)) {
      return this._httpClient.getData(`${this._tomClosedOrders}?startDate=${startDate}&endDate=${endDate}`);
    } else {
      return this._httpClient.getData(`${this._tomClosedOrders}?startDate=${startDate}&endDate=${endDate}&blockId=${blockId}`);
    }
  }

  GetAllClosedBlocks(startDate: string, endDate: string) {
    return this._httpClient.getData(`${this._tomClosedOrders}/getBlocks?startDate=${startDate}&endDate=${endDate}`);
  }

  getOrdersbyInstanceId(id: number) {
    return this._httpClient.getData(`${this._tomInstances}/${id}/trades?status=closed`);
  }

  // gets trade filters
  getTradefilters() {
    return this._httpClient.getData(`${this._tomEndPoint}/filterTypes`);

  }

  getTradeOrderAuditHistory(id: number, toDate, fromDate) {
    return this._httpClient.getData(`${this._tomAuditLogEndPoint}/${id}?toDate=${toDate}&fromDate=${fromDate}`);
  }

  getTradeBlockAuditHistory(id: number, fromDate, toDate) {
    return this._httpClient.getData(`${this._tomBlockAuditLogEndPoint}/${id}?toDate=${toDate}&fromDate=${fromDate}`);
  }

  /** Get Trade Order Audit history for closed orders */
  getTradeOrderCOAuditHistory(id: number, toDate, fromDate) {
    return this._httpClient.getData(`${this._tomAuditLogEndPoint}/closed/${id}?toDate=${toDate}&fromDate=${fromDate}`);
  }

  deleteTradeOrderForSelectedSecurities(data: any) {
    return this._httpClient.updateData(`${this._tomEndPoint}/action/deleteForSelectedSecurities`, data);
  }

  getFixedIncomeTrades() {
    return this._httpClient.getData(`${this._tomEndPoint}/fixedIncome`);
  }

  /**Import Fixed Income Order Excel */
  importFixedIncomeTradeFile(file: any) {
    const formData: any = new FormData();
    formData.append('document', file);
    return this._httpClient.uploadFile(this._fixedIncomeImportEndPoint, formData);
  }

  // update on grid cell edit
  updateTradeOrderStatus(id, order: any) {
    return this._httpClient.updateData(`${this._tomEndPoint}/${id}/updateTradeOrderStatus`, order);
  }

  processFixedIncomeTradeOrders(data: any) {
    return this._httpClient.updateData(`${this._tomEndPoint}/action/fixedIncome/processTrades`, data);
  }

  /**save valid Fixed income uploaded Trades */
  saveUploadFixedIncomeTrades(trades) {
    return this._httpClient.postData(this._saveFixedIncomeTradEndPoint, trades);
  }

  /**get trade analysis report info */
  getTradePortfolioAnalysis(tradeIds: number[]) {
    return this._httpClient.postData(`${this._tomReports}/portfolio`, {tradeIds});
  }

  getAllTradeOrderMessages(): Observable<ITradeOrderMessage[]> {
    return this._httpClient.getData(this._tradeOrderMessagesEndpoint);
  }

  autoAllocateBlock(blockId: number, autoAllocateType: AutoAllocateType): Observable<IBlockOrderAllocation[]> {
    return this._httpClient.updateData(`${this._autoAllocateBlockEndpoint}?blockId=${blockId}&tradeAllocateAutoAdjustType=${autoAllocateType}`, null);
  }

  splitBlock(data: ISplitBlockRequest): Observable<ITradeBlock[]> {
    return this._httpClient.updateData(`${this._tomBlocksEndPoint}/action/splitBlock`, data);
  }

  splitTradeAwayBlock(data: ISplitBlockRequest): Observable<ITradeBlock[]> {
    return this._httpClient.postData(`${this._tradeAwayBlockEndpoint}/SplitTradeAwayBlock`, data);
  }

  updateTradeBlocks(blocksData: IMassEditBlock): Observable<number[]> {
    return this._httpClient.postData(`${this._tradeAwayBlockEndpoint}/UpdateTradeBlocks`, blocksData);
  }
}
