import { AfterViewInit, Component, ViewChild } from '@angular/core';
import {
  GridOptions, ColDef, CsvExportParams, GridApi, GridReadyEvent,
  ICellRendererParams, ITooltipParams, GetRowIdParams,
  ValueGetterParams
} from '@ag-grid-community/core';
import { Observable, forkJoin, Subscription, timer } from 'rxjs';
import { IIdName, IModelTolerance } from '../../../models/tom';
import { GroupBarChartComponent } from '../../../shared/charts/groupchart/groupbarchart.component';
import { PortfolioService } from '../../../services/portfolio.service';
import { NotificationService } from '../../../core/customSubService';
import { TomService } from '../../../services/tom.service';
import { Utils as Util } from '../../../core/functions';
import * as d3 from 'd3';
import { tap } from 'rxjs/operators';
import { BaseComponent } from '../../../core/base.component';
import { AccountService } from '../../../services/account.service';
import { IPortfolioAnalytics } from '../../../models/portfolio';
import { AnalyticsDurationComponent } from '../../../shared/analytics-duration/analytics-duration.component';
import { INotificationVM } from '../../../models/notification';
import { NotificationService as EclipseNotificationService } from '../../../services/notification.service';
import { SplitIoService } from '../../../core/feature-flag/splitio.service';
import { IExcludedCashDetails } from '../../../models/modeling/model';
import { AmountFormatCurrencyPipe, PercentageFormatPipe } from '../../../pipes/FilterPipe';
import { AnalyticsStatus, ExcludedSecurity, Messages, RESET_EXCLUDED_CASH_VALUES, TAB_VIEW_TYPE, tradeDecimalEnum } from '../../../libs/app.constants';
import { SECURITY_RELATED_TYPE } from '../../../libs/security.constants';
import { ModelTypeName } from '../../../libs/model.constants';

enum ViewType {
  Percent = 1,
  Dollars = 2,
  Shares = 3
}

enum BarChartType {
  Pending = 1,
  Current = 2
}

export interface IModelToleranceData {
  portfolio: {
    id: number;
    isSleevedPorfolio: boolean;
    name: string;
    numOfAssociatedAccounts: number;
  };
  account: {
    accountId: string;
    name: string;
  };
  model: any;
  currentModelId: number;
}

@Component({
  selector: 'eclipse-model-tolerance',
  templateUrl: './modeltolerance.component.html'
})
export class ModelToleranceComponent extends BaseComponent implements AfterViewInit {
  @ViewChild(GroupBarChartComponent) groupBarChartComponent: GroupBarChartComponent;
  @ViewChild(AnalyticsDurationComponent) analyticsDurationComponent: AnalyticsDurationComponent;

  readonly percentageColumns = ['currentInPercentage', 'targetInPercentage', 'differenceInPercentage', 'postTradeInPercentage'];
  readonly dollarColumns = ['currentInDollar', 'targetInDollar', 'differenceInDollar', 'postTradeInDollar'];
  readonly sharesColumns = ['currentInShares', 'targetInShares', 'differenceInShares', 'postTradeInShares'];

  private gridApi: GridApi;
  isPortfolioTab: boolean;
  gridOptions: GridOptions;
  columnDefs: ColDef[];
  gridRowData: IModelTolerance[];
  orderSecurities: IModelTolerance[];
  orderSecuritySet: IModelTolerance[];
  orderSubClasses: IModelTolerance[];
  orderClasses: IModelTolerance[];
  orderCategories: IModelTolerance[];
  modelLevels: IIdName[];
  modelToleranceData: IModelToleranceData = <IModelToleranceData>{};
  selectedLevelId: number;
  selectedCellValueType: ViewType = ViewType.Percent;
  selectedTab: string = 'SECURITY';
  tabs: string[] = ['', 'category', 'class', 'subclass', 'securityset'];
  needAnalytics: number;
  decimalsForDollar: number = 2;
  modelTolSetAsideCash: number = null;
  selectedBarChartType: BarChartType = BarChartType.Pending;
  modelToleranceSubscription: Subscription;
  refreshSubscription: Subscription;
  dataLoadSubscription: Subscription;
  portfolioAnalytics: IPortfolioAnalytics[];
  analyticsConnection: any;
  excludedCashTarget: string;
  excludedCashActual: string;
  excludedCashDetails: IExcludedCashDetails;
  isModelToleranceForExcludedFF: boolean;
  isModelToleranceUpdatesFF: boolean = false;
  showOutOfToleranceInRed: boolean = false;
  totalsByParentSecurityId = [];
  orderSleeves: IModelTolerance[];
  orderSleeveStrategy: IModelTolerance[];
  isSleevePortfolioTabSelected: boolean;
  gridContext = {
    self: this
  }
  showModelToleranceNoModel : boolean = false;
  useOutOfToleranceFlag : boolean = false;
  showModelToleranceForSleevedPortfolioFF: boolean = false;

  private selectedLevel: IIdName;
  SECURITY_TAB = ModelTypeName.SECURITY;

  constructor(private _notifier: NotificationService, private _tomService: TomService, private _portfolioService: PortfolioService,
              private  _accountService: AccountService, private _splitIoService: SplitIoService,  private readonly _notificationService: EclipseNotificationService) {
    super();
    this.gridOptions = {
      ...this.defaultGridOptions,
      sideBar: null,
    };
    this.gridRowData = <IModelTolerance[]>[];
    this.orderSecurities = <IModelTolerance[]>[];
    this.orderSubClasses = <IModelTolerance[]>[];
    this.orderSecuritySet = <IModelTolerance[]>[];
    this.orderClasses = <IModelTolerance[]>[];
    this.orderCategories = <IModelTolerance[]>[];
    this.orderSleeves = <IModelTolerance[]>[];
    this.orderSleeveStrategy = <IModelTolerance[]>[];
    this.portfolioAnalytics = [];
    this.modelLevels = [{id: 4, name: 'Security Set'}];
    this.selectedLevelId = 0;
    this.subscribeToNotifications();

    this._splitIoService.flagsEnabled$(['model_tolerance_for_excluded_s3943', 'model_tolerance_updates_e2878',
        'TEXP_model_tolerance_OOT_7708',
        'TEXP_api_sum_eq_alt_model_tolerance_8550', 'TEXP_sleeve_portfolio_model_tolerance_7545'])
      .subscribe((flags: { [key: string]: boolean }) => {
        this.isModelToleranceForExcludedFF = flags['model_tolerance_for_excluded_s3943'];
        this.isModelToleranceUpdatesFF = flags['model_tolerance_updates_e2878'];
        this.showOutOfToleranceInRed = flags['TEXP_model_tolerance_OOT_7708'];
        this.useOutOfToleranceFlag = flags['TEXP_api_sum_eq_alt_model_tolerance_8550'];
        this.showModelToleranceForSleevedPortfolioFF = flags['TEXP_sleeve_portfolio_model_tolerance_7545'];

        // TODO: 'model_tolerance_updates_e2878': Move this function outside this callback when feature flag is removed.
        this.createColumnDefForSelectedTabs();
      });
  }

  ngAfterViewInit() {
    this.refreshSubscription = this._notifier.tomRightPanelMT.subscribe((data: any) => {
        this.loadData(data);
      }
    );
  }

  ngOnDestroy() {
    this.refreshSubscription?.unsubscribe();
    this.modelToleranceSubscription?.unsubscribe();
    this.dataLoadSubscription?.unsubscribe();
    this.analyticsConnection?.unsubscribe();
  }

  onGridReady(event: GridReadyEvent) {
    this.gridApi = event.api;
  }

  public loadData(data: IModelToleranceData): void {
    const loadDataObs = [];
    const previousAccountId = this.modelToleranceData?.account?.accountId;
    const previousPortfolioId = this.modelToleranceData?.portfolio?.id;

    this.modelToleranceData = data || <IModelToleranceData>{};
    this.isPortfolioTab = !data?.account?.accountId;

    if (this.showModelToleranceForSleevedPortfolioFF) {
      this.isSleevePortfolioTabSelected = this.isPortfolioTab && this.modelToleranceData?.portfolio?.isSleevedPorfolio;
      this.showModelToleranceNoModel = (!!data?.account?.accountId ||
        data?.portfolio?.numOfAssociatedAccounts === 1 || this.isSleevePortfolioTabSelected);
    } else {
      this.showModelToleranceNoModel = (!!data.account?.accountId || data.portfolio?.numOfAssociatedAccounts === 1);
    }

    if ((!data?.model || !data.currentModelId) && !this.showModelToleranceNoModel) {
      this.disposeData();
      this.portfolioAnalytics = [];
      return;
    }

    const isAccountChanged = data.account?.accountId !== previousAccountId;
    const isPortfolioChanged = data.portfolio?.id !== previousPortfolioId;

    if (isAccountChanged || isPortfolioChanged) {
      this.disposeData();
      this.portfolioAnalytics = [];
      this.setFloatingRowsValue();

      if (this.showModelToleranceForSleevedPortfolioFF) {
        if (this.isSleevePortfolioTabSelected) {
          this.loadModelToleranceForSleevePortfolio(data.portfolio.id);
        } else {
          this.loadModelToleranceForNonSleevePortfolio(data);
        }
      } else {
        if (data?.currentModelId) {
          loadDataObs.push(this.loadModelLevels(data.currentModelId));
        } else {
          this.selectedLevelId = 5;
          this.selectedTab = 'SECURITY';
          this.modelLevels = [{
            id: 5,
            name: 'SECURITY',
            displayName: 'Security'
          }];
        }
        loadDataObs.push(this.getPortfolioFlag(data.portfolio.id, false));
        this.dataLoadSubscription = forkJoin(loadDataObs).subscribe(() => {
          this.loadModelTolerance(this.modelToleranceData);
        });
      }
    }
  }

  /**
   * This method load tab levels, create column defs and load tolerances for sleeve portfolio
   * @param {number} portfolioId
   */
  loadModelToleranceForSleevePortfolio(portfolioId: number): void {
    this.loadSleevePortfolioLevel();
    this.createColumnDefForSelectedTabs();
    this.dataLoadSubscription = this.getPortfolioFlag(portfolioId, false)
      .subscribe(() => {
        this.loadModelTolerance(this.modelToleranceData);
      });
  }

  /**
   * This method load tab levels, create column defs and load tolerances for non sleeve portfolio
   * @param {IModelToleranceData} modelToleranceData
   */
  loadModelToleranceForNonSleevePortfolio(modelToleranceData: IModelToleranceData): void {
    const loadDataObs = <Observable<IIdName[]>[]>[];
    if (modelToleranceData?.currentModelId) {
      loadDataObs.push(this.loadModelLevels(modelToleranceData.currentModelId));
    } else {
      this.selectedTab = this.SECURITY_TAB;
      this.selectedLevelId = 5;
      this.modelLevels = [{
        id: 5,
        name: ModelTypeName.SECURITY,
        displayName: 'Security'
      }];
    }
    loadDataObs.push(this.getPortfolioFlag(modelToleranceData.portfolio.id, false));
    this.dataLoadSubscription = forkJoin(loadDataObs).subscribe(() => {
      this.createColumnDefForSelectedTabs();
      this.loadModelTolerance(this.modelToleranceData);
    });
  }

  subscribeToNotifications(): void {
    this.analyticsConnection = this._notificationService.analyticsPortfolioStatus
      .subscribe((notification: INotificationVM) => {
        const analyticsNotification = notification.analyticsNotification;
        if (analyticsNotification?.isAnalyticsCompleted) {
          if(this.portfolioAnalytics?.length  && analyticsNotification.portfolioFlags?.filter(pf => pf.portfolioId === this.modelToleranceData?.portfolio?.id && pf.needAnalytics === 0).length  > 0) {
            this.needAnalytics = 0;
            this.loadModelTolerance(this.modelToleranceData);
          }
        }
      });
  }

  // TODO: "model_tolerance_updates_e2878": remove this function when feature flag is killed.
  onTabChangeLegacy(tabName: string, levelId: number): void {
    this.selectedLevelId = levelId;
    this.selectedTab = tabName;
    let gridRowData: any[];
    switch (this.selectedTab) {
      case 'SUBCLASS':
        gridRowData = Util.deepClone(this.orderSubClasses);
        break;
      case 'CLASS':
        gridRowData = Util.deepClone(this.orderClasses);
        break;
      case 'CATEGORY':
        gridRowData = Util.deepClone(this.orderCategories);
        break;
      case 'SECURITYSET':
        gridRowData = Util.deepClone(this.orderSecuritySet);
        break;
      case ModelTypeName.SLEEVE:
        gridRowData = Util.deepClone(this.orderSleeves);
        break;
      case ModelTypeName.SLEEVE_STRATEGY:
        gridRowData = Util.deepClone(this.orderSleeveStrategy);
        break;
      default:
        gridRowData = Util.deepClone(this.orderSecurities);
    }
    if (gridRowData?.length) {
      this.gridRowData = gridRowData;
      this.createBarGraphChart(gridRowData);
      this.setFloatingRowsValue();
      this.gridApi.setGridOption('rowData', this.gridRowData);
      this.setColumnsVisible(this.selectedCellValueType);
    } else {
      this.loadModelTolerance(this.modelToleranceData);
    }
  }

  onTabChange(tabName: string, levelId: number, updateColumnDefs = false): void {
    this.selectedLevelId = levelId;
    this.selectedTab = tabName;
    let gridRowData: IModelTolerance[];

    switch (this.selectedTab) {
      case ModelTypeName.SUBCLASS:
        gridRowData = Util.deepClone(this.orderSubClasses);
        break;
      case ModelTypeName.CLASS:
        gridRowData = Util.deepClone(this.orderClasses);
        break;
      case ModelTypeName.CATEGORY:
        gridRowData = Util.deepClone(this.orderCategories);
        break;
      case ModelTypeName.SECURITY_SET:
        gridRowData = Util.deepClone(this.orderSecuritySet);
        break;
      case ModelTypeName.SLEEVE:
        gridRowData = Util.deepClone(this.orderSleeves);
        break;
      case ModelTypeName.SLEEVE_STRATEGY:
        gridRowData = Util.deepClone(this.orderSleeveStrategy);
        break;
      default:
        gridRowData = Util.deepClone(this.orderSecurities);
    }

    if (updateColumnDefs) {
      this.createColumnDefForSelectedTabs();
    }

    if (gridRowData?.length) {
      this.gridRowData = gridRowData;
      this.updateGridData();
    } else {
      this.loadModelTolerance(this.modelToleranceData);
    }
  }

  updateGridData(): void {
    const timerPref = timer(0);
    timerPref.subscribe(() => {
      this.createBarGraphChart(this.gridRowData);
      this.setFloatingRowsValue();
      this.gridApi.setGridOption('rowData', this.gridRowData);
      this.setColumnsVisible(this.selectedCellValueType);
    });
  }

  createBarGraphChart(gridRowData: IModelTolerance[]) {
    if (this.isModelToleranceUpdatesFF) {
      gridRowData = gridRowData.filter(row => !row.isExcluded);
    }

    if(this.showOutOfToleranceInRed) {
      gridRowData = gridRowData.filter(row => row.securityId === row.parentSecurityId || !row.parentSecurityId);
    }

    const barGraphData = [
      {name: 'Lower', data: []},
      {name: 'Upper', data: []},
      {name: 'Post_Trade', data: []}
    ];
    const targetArray = [];
    gridRowData.forEach(item => {
      let currentInPercentage = item.currentInPercentage;
      let postTradeInPercentage = item.postTradeInPercentage;
      let postOutOfTolerance = item.outofTolerance;
      let currentOutOfTolerance = (currentInPercentage > item.upperModelTolerancePercentage || currentInPercentage < item.lowerModelTolerancePercentage) ? 1 : 0;

      if(this.showOutOfToleranceInRed && item.parentSecurityId) {
        let aggValues = this.totalsByParentSecurityId?.filter(p => p.parentSecurityId === item.parentSecurityId);
        if(aggValues && aggValues.length === 1) {
          let aggValue = aggValues[0];
          currentInPercentage = aggValue.currentInPercentage;
          postTradeInPercentage = aggValue.postTradeInPercentage;

          if(!this.useOutOfToleranceFlag) {
            postOutOfTolerance = (postTradeInPercentage > item.upperModelTolerancePercentage || postTradeInPercentage < item.lowerModelTolerancePercentage) ? 1 : 0;
          }

          currentOutOfTolerance = (currentInPercentage > item.upperModelTolerancePercentage || currentInPercentage < item.lowerModelTolerancePercentage) ? 1 : 0;
        }

        if(this.showModelToleranceNoModel && item.targetInPercentage === 0 && item.differenceInPercentage === 0) {
          postOutOfTolerance = 0;
          currentOutOfTolerance = 0;
        }
      }

      barGraphData[0].data.push({
        label: this.getGraphLabel(item),
        value: -(item.targetInPercentage - item.lowerModelTolerancePercentage),
        series: 'Lower',
        postTrade: postTradeInPercentage,
        lowerRange: item.lowerModelTolerancePercentage,
        upperRange: item.upperModelTolerancePercentage,
        upper: item.upperModelTolerancePercentage,
        lower: item.lowerModelTolerancePercentage,
        target: item.targetInPercentage,
        symbol: this.getGraphSymbol(item),
        outOfTolerance: item.outofTolerance
      });
      barGraphData[1].data.push({
        label: this.getGraphLabel(item),
        value: item.upperModelTolerancePercentage - item.targetInPercentage,
        series: 'Upper',
        postTrade: postTradeInPercentage,
        lowerRange: item.lowerModelTolerancePercentage,
        upperRange: item.upperModelTolerancePercentage,
        upper: item.upperModelTolerancePercentage,
        lower: item.lowerModelTolerancePercentage,
        target: item.targetInPercentage,
        symbol: this.getGraphSymbol(item),
        outOfTolerance: item.outofTolerance
      });
      barGraphData[2].data.push({
        label: this.getGraphLabel(item),
        value: (this.selectedBarChartType === BarChartType.Current)? currentInPercentage - item.targetInPercentage : postTradeInPercentage - item.targetInPercentage,
        series: 'Post_Trade',
        postTrade: postTradeInPercentage,
        lowerRange: item.lowerModelTolerancePercentage,
        upperRange: item.upperModelTolerancePercentage,
        upper: item.upperModelTolerancePercentage,
        lower: item.lowerModelTolerancePercentage,
        target: item.targetInPercentage,
        symbol: this.getGraphSymbol(item),
        outOfTolerance: (this.selectedBarChartType === BarChartType.Current)? currentOutOfTolerance : postOutOfTolerance
      });
      targetArray.push(item.upperModelTolerancePercentage);
      targetArray.push(item.lowerModelTolerancePercentage);
      targetArray.push(postTradeInPercentage);
    });
    const min = d3.min(targetArray);
    const max = d3.max(targetArray);
    const target: any = (min + max) / 2;
    // get portfolio name from the list
    const portfolioName = this.getLabelForGraph();
    barGraphData.forEach(val => {
      val['target'] = parseInt(target, 10);
      val['portfolioName'] = portfolioName;
    });
    $('.group_error_message').hide();
    this.groupBarChartComponent.CreateSvgForgroupChart(barGraphData);
  }

  /**
   * This method returns the label name to be used in graph
   * @param {IModelTolerance} modelTolerance
   * @returns {string} LabelName
   */
  getGraphLabel(modelTolerance: IModelTolerance): string {
    if (this.showModelToleranceForSleevedPortfolioFF && this.isSleevePortfolioTabSelected) {
      return this.selectedTab === ModelTypeName.SLEEVE ?
        modelTolerance.suffix :
        modelTolerance.sleeveStrategyName;
    }
    return modelTolerance.assetName;
  }

   /**
   * This method returns the Symbol name to be used in graph
   * @param {IModelTolerance} modelTolerance
   * @returns {string} Symbol
   */
  getGraphSymbol(modelTolerance: IModelTolerance): string {
    if (this.showModelToleranceForSleevedPortfolioFF && this.isSleevePortfolioTabSelected) {
      return this.selectedTab === ModelTypeName.SLEEVE ?
        modelTolerance.suffix :
        modelTolerance.sleeveStrategyName;
    }
    return modelTolerance.assetSymbol;
  }

  getLabelForGraph(): string {
    let labelName = '';

    if (this.showModelToleranceForSleevedPortfolioFF) {
      if (this.modelToleranceData?.portfolio) {
        const { portfolio, account } = this.modelToleranceData;
        labelName = portfolio.isSleevedPorfolio && !this.isSleevePortfolioTabSelected ?
          `${account.name} (Sleeve)` :
          portfolio.name;
      }
    } else {
      const trade = this.modelToleranceData;
      if (!!trade?.portfolio) {
        if (trade.portfolio.isSleevedPorfolio === true) {
          labelName = `${trade.account.name} (Sleeve)`;
        } else {
          labelName = trade.portfolio.name;
        }
      }
    }

    return labelName;
  }

  disposeBarGraphChart() {
    this.groupBarChartComponent?.removeBarGraphChart();
  }

  /**
   * This method creates and loads levels for sleeved Portfolio
   */
  loadSleevePortfolioLevel(): void {
    const levels: IIdName[] = [
      { id: 7, name: ModelTypeName.SLEEVE, displayName: 'Sleeve*' },
      { id: 6, name: ModelTypeName.SLEEVE_STRATEGY, displayName: 'Sleeve Strategy' }
    ];
    this.modelLevels = Util.sortBy(levels, 'id');
    const ids = this.modelLevels.map(level => level.id);
    const maxId = Math.max(...ids);
    this.selectedLevel = this.modelLevels.find(level => Number(level.id) === maxId);
    this.selectedLevelId = Number(this.selectedLevel.id);
    this.selectedTab = this.selectedLevel.name;
  }

  loadModelLevels(modelId: number): Observable<IIdName[]> {
    return this._tomService.getModelLevels(modelId)
      .pipe(tap((levels: IIdName[]) => {
        if (levels?.length) {
          this.modelLevels = Util.sortBy(levels, 'id');
          const ids = this.modelLevels.map(x => x.id);
          const maxId = Math.max(...ids);
          this.selectedLevel = this.modelLevels.find(level => Number(level.id) === maxId);
          this.selectedLevelId = Number(this.selectedLevel.id);
          this.selectedTab = this.selectedLevel.name;
        }
      }));
  }

  // TODO: model_tolerance_for_excluded_s3943 : Remove this method when feature flag is killed.
  getModelToleranceSetAsideCash(accountId: string) {
    return this._accountService.getModelToleranceSetAsideCash(accountId)
      .subscribe( data => {
          this.modelTolSetAsideCash = data;
      });
  }

  loadModelTolerance(trade: IModelToleranceData) {
    if (this.isModelToleranceForExcludedFF) {
      const pIdType = this.isPortfolioTab ? TAB_VIEW_TYPE.PORTFOLIO : TAB_VIEW_TYPE.ACCOUNT;
      const accountPortfolioId = this.isPortfolioTab ? (trade.portfolio.id).toString() : trade.account.accountId;
      this.getModelToleranceExcludedCashValues(accountPortfolioId, pIdType);
    } else {
      this.getModelToleranceSetAsideCash(trade.account.accountId);
    }
    this.modelToleranceSubscription?.unsubscribe();
    this.modelToleranceSubscription = forkJoin([
      this._tomService.getModelTolerance(this.selectedTab, trade.portfolio.id, trade.account.accountId, trade.portfolio.isSleevedPorfolio, this.selectedLevelId),
      this._tomService.getModelMACTolerance(trade.portfolio.id, trade.account.accountId, trade.portfolio.isSleevedPorfolio)
    ]).pipe(tap(data => {
      if(data?.length > 0) {
        // Rename CUSTODIAL_CASH and CASH to Cash.
        data[0]
          .filter(d => d.assetSymbol?.toLowerCase() === 'custodial_cash')
          .forEach(d => d.assetSymbol = '* Cash *');
        data[0]
          .filter(d => d.assetName?.toLowerCase() === 'cash')
          .forEach(d => d.assetName = '* Cash *');
      }
    }))
      .subscribe(dataSubModel => {
        if (this.needAnalytics === 0) {
          this.setGridRowData(dataSubModel);
          switch (this.selectedTab) {
            case 'SECURITYSET':
              this.orderSecuritySet = Util.deepClone(this.gridRowData);
              break;
            case 'SUBCLASS':
              this.orderSubClasses = Util.deepClone(this.gridRowData);
              break;
            case 'CLASS':
              this.orderClasses = Util.deepClone(this.gridRowData);
              break;
            case 'CATEGORY':
              this.orderCategories = Util.deepClone(this.gridRowData);
              break;
            case ModelTypeName.SLEEVE:
              this.orderSleeves = Util.deepClone(this.gridRowData);
              break;
            case ModelTypeName.SLEEVE_STRATEGY:
              this.orderSleeveStrategy = Util.deepClone(this.gridRowData);
              break;
            default:
              this.orderSecurities = Util.deepClone(this.gridRowData);
          }

          if(this.selectedTab === 'SECURITY') {
            this.totalsByParentSecurityId = this.aggregateSum(this.gridRowData, "parentSecurityId", ["currentInPercentage", "postTradeInPercentage"]);
          }
          this.createBarGraphChart(this.gridRowData);
          this.setFloatingRowsValue();
          this.setColumnsVisible(this.selectedCellValueType);
        }
      });
  }

  setGridRowData(dataSubModel) {
    if (this.selectedTab !== 'SECURITYSET') {
      this.gridRowData = dataSubModel[0];
    } else {
      const data = dataSubModel[0];
      // fix for OE-1942 added if condition to avoid undefined error
      if (dataSubModel[1].length > 0) {
        data.push(dataSubModel[1]['MACFund']);
      }
      this.gridRowData = data;
    }
    // fix for beta issue 245 from master sheet
    if (this.selectedTab !== 'SECURITY') {
      this.gridRowData.forEach(m => {
        m.assetSymbol = m.assetName;
      });
    } else if (!this.isModelToleranceUpdatesFF) {
      this.gridRowData.forEach(m => {
        m.assetSymbol = m.isModelSecurity ? m.assetSymbol : `${m.assetSymbol}*`;
      });
    } else if (this.isModelToleranceUpdatesFF) {
      this.updateSecurityAssetSymbol();
    }
  }

  updateSecurityAssetSymbol(): void {
    for (const row of this.gridRowData) {
      row.hierarchyIds = [row.parentSecurityId ?? row.securityId];
      let symbolPrefix = '';
      if (row.securityRelatedType === SECURITY_RELATED_TYPE.EQUIVALENT) {
        row.hierarchyIds.push(row.securityId);
        symbolPrefix = '-';
      } else if (row.securityRelatedType === SECURITY_RELATED_TYPE.ALTERNATE) {
        row.hierarchyIds.push(row.securityId);
        symbolPrefix = '~';
      } else if (row.isExcluded) {
        symbolPrefix = '!';
      }
      const assetSymbol = row.isModelSecurity || row.isExcluded ? row.assetSymbol : `${row.assetSymbol}*`;
      row.assetSymbol = `${symbolPrefix}${assetSymbol}`;
    }
  }

  aggregateSum(arr, key: string, fields: string[]) {
    // using reduce() method to aggregate
    const agg = arr.reduce((prev, cur) => {
      // get the value of both the keys
      const keyValue = cur[key];

      if(keyValue) {
        fields.forEach(field => {
          const fieldValue = cur[field];

          if(!prev[keyValue]) {
            prev[keyValue] = {
              [key]: keyValue
            };
          }

          if(prev[keyValue][field]) {
            prev[keyValue][field] += fieldValue;
          }
          else{
            prev[keyValue][field] =  fieldValue;
          }
        });
      }

      // return the aggregation
      return prev;
    }, []);


    // return only values after aggregation
    return Object.values(agg);
  }

  /** Create column headers for agGrid */
  createColumnDefs() {
    this.columnDefs = [
      <ColDef>{
        colId: 'name',
        headerName: 'Name',
        field: 'assetSymbol',
        tooltipField: 'assetName',
        width: 145,
        cellClass: 'text-left'
      },
      <ColDef>{
        headerName: 'Current',
        field: 'currentInPercentage',
        cellRenderer: (params) => Util.percentageCellRenderer(params, 2),
        width: 103,
        cellClass: 'text-right'
      },
      <ColDef>{
        headerName: 'Target',
        field: 'targetInPercentage',
        cellRenderer: (params) => Util.percentageCellRenderer(params, 2),
        width: 103,
        cellClass: 'text-right'
      },
      <ColDef>{
        headerName: 'Difference',
        field: 'differenceInPercentage',
        cellRenderer: (params) => Util.percentageCellRenderer(params, 2),
        width: 103,
        cellClass: 'text-right',
        cellClassRules: this.toleranceCellClassRules
      },
      <ColDef>{
        headerName: this.isModelToleranceUpdatesFF ? 'Post' : 'Post Trade',
        field: 'postTradeInPercentage',
        cellRenderer: (params) => Util.percentageCellRenderer(params, 2),
        width: 103,
        cellClass: 'text-right'
      },
      <ColDef>{
        headerName: 'Current',
        field: 'currentInDollar',
        cellRenderer: (params) => Util.currencyCellRenderer(params, 2),
        hide: true,
        width: 125,
        cellClass: 'text-right',
        tooltipValueGetter: Util.currencyValueForTooltip,
      },
      <ColDef>{
        headerName: 'Target',
        field: 'targetInDollar',
        cellRenderer: (params) => Util.currencyCellRenderer(params, 2),
        hide: true,
        width: 125,
        cellClass: 'text-right'
      },
      <ColDef>{
        headerName: 'Difference',
        field: 'differenceInDollar',
        cellRenderer: (params) => Util.currencyCellRenderer(params, 2),
        hide: true,
        width: 125,
        cellClass: 'text-right',
        tooltipValueGetter: Util.currencyValueForTooltip,
        cellClassRules: this.toleranceCellClassRules
      },
      <ColDef>{
        headerName: this.isModelToleranceUpdatesFF ? 'Post' : 'Post Trade',
        field: 'postTradeInDollar',
        cellRenderer: (params) => Util.currencyCellRenderer(params, 2),
        hide: true,
        width: 125,
        cellClass: 'text-right',
        tooltipValueGetter: Util.currencyValueForTooltip,
      },
      <ColDef>{
        headerName: 'Current',
        field: 'currentInShares',
        cellRenderer: (params) => Util.sharesCellRenderer(params, 2),
        hide: true,
        width: 103,
        cellClass: 'text-right',
        tooltipValueGetter: Util.currencyValueForTooltip,
      },
      <ColDef>{
        headerName: 'Target',
        field: 'targetInShares',
        cellRenderer: (params) => Util.sharesCellRenderer(params, 2),
        hide: true,
        width: 103,
        cellClass: 'text-right'
      },
      <ColDef>{
        headerName: 'Difference',
        field: 'differenceInShares',
        cellRenderer: (params) => Util.sharesCellRenderer(params, 2),
        hide: true,
        width: 103,
        cellClass: 'text-right',
        cellClassRules: this.toleranceCellClassRules
      },
      <ColDef>{
        headerName: this.isModelToleranceUpdatesFF ? 'Post' : 'Post Trade',
        field: 'postTradeInShares',
        cellRenderer: (params) => Util.sharesCellRenderer(params, 2),
        hide: true,
        width: 103,
        cellClass: 'text-right'
      }
    ];

    if (this.showModelToleranceForSleevedPortfolioFF && this.isSleevePortfolioTabSelected) {
      this.columnDefs.shift();

      if (this.selectedTab === ModelTypeName.SLEEVE) {
        this.columnDefs.unshift(
          <ColDef>{
            colId: 'suffix',
            headerName: 'Suffix',
            field: 'suffix',
            tooltipField: 'suffix',
            width: 145,
            cellClass: 'text-left'
          },
          <ColDef>{
            colId: 'modelName',
            headerName: 'Model Name',
            field: 'modelName',
            tooltipField: 'modelName',
            width: 145,
            cellClass: 'text-left',
            valueGetter: (params: ValueGetterParams<IModelTolerance>) => params.data?.modelName ?? 'No model assigned'
          }
        );
      } else {
        this.columnDefs.unshift(
          <ColDef>{
            colId: 'sleeveStrategy',
            headerName: 'Sleeve Strategy',
            field: 'sleeveStrategyName',
            tooltipField: 'sleeveStrategy',
            width: 145,
            cellClass: 'text-left'
          }
        );
      }
    }
  }

  createColumnDefsForSecurityTab(): void {
    this.columnDefs = [
      <ColDef>{
        headerName: 'Current',
        field: 'currentInPercentage',
        cellRenderer: (params: ICellRendererParams<IModelTolerance>) => Util.percentageCellRenderer(params, tradeDecimalEnum.amountDecimal),
        width: 103,
        cellClass: 'text-right'
      },
      <ColDef>{
        headerName: 'Target',
        field: 'targetInPercentage',
        cellRenderer: (params: ICellRendererParams<IModelTolerance>) => Util.percentageCellRenderer(params, tradeDecimalEnum.amountDecimal),
        width: 103,
        cellClass: 'text-right',
        tooltipValueGetter: this.TargetAndDifferenceToolTipValueGetter
      },
      <ColDef>{
        headerName: 'Difference',
        field: 'differenceInPercentage',
        cellRenderer: (params: ICellRendererParams<IModelTolerance>) => Util.percentageCellRenderer(params, tradeDecimalEnum.amountDecimal),
        width: 103,
        cellClass: 'text-right',
        tooltipValueGetter: this.TargetAndDifferenceToolTipValueGetter,
        cellClassRules: this.toleranceCellClassRules
      },
      <ColDef>{
        headerName: 'Post',
        field: 'postTradeInPercentage',
        cellRenderer: (params: ICellRendererParams<IModelTolerance>) => Util.percentageCellRenderer(params, tradeDecimalEnum.amountDecimal),
        width: 103,
        cellClass: 'text-right'
      },
      <ColDef>{
        headerName: 'Current',
        field: 'currentInDollar',
        cellRenderer: (params: ICellRendererParams<IModelTolerance>) => Util.currencyCellRenderer(params, tradeDecimalEnum.amountDecimal),
        hide: true,
        width: 125,
        cellClass: 'text-right',
        tooltipValueGetter: Util.currencyValueForTooltip
      },
      <ColDef>{
        headerName: 'Target',
        field: 'targetInDollar',
        cellRenderer: (params: ICellRendererParams<IModelTolerance>) => Util.currencyCellRenderer(params, tradeDecimalEnum.amountDecimal),
        hide: true,
        width: 125,
        cellClass: 'text-right',
        tooltipValueGetter: this.TargetAndDifferenceToolTipValueGetter
      },
      <ColDef>{
        headerName: 'Difference',
        field: 'differenceInDollar',
        cellRenderer: (params: ICellRendererParams<IModelTolerance>) => Util.currencyCellRenderer(params, tradeDecimalEnum.amountDecimal),
        hide: true,
        width: 125,
        cellClass: 'text-right',
        tooltipValueGetter: this.TargetAndDifferenceToolTipValueGetter,
        cellClassRules: this.toleranceCellClassRules
      },
      <ColDef>{
        headerName: 'Post',
        field: 'postTradeInDollar',
        cellRenderer: (params: ICellRendererParams<IModelTolerance>) => Util.currencyCellRenderer(params, tradeDecimalEnum.amountDecimal),
        hide: true,
        width: 125,
        cellClass: 'text-right',
        tooltipValueGetter: Util.currencyValueForTooltip
      },
      <ColDef>{
        headerName: 'Current',
        field: 'currentInShares',
        cellRenderer: (params: ICellRendererParams<IModelTolerance>) => Util.sharesCellRenderer(params, tradeDecimalEnum.amountDecimal),
        hide: true,
        width: 103,
        cellClass: 'text-right',
        tooltipValueGetter: Util.currencyValueForTooltip
      },
      <ColDef>{
        headerName: 'Target',
        field: 'targetInShares',
        cellRenderer: (params: ICellRendererParams<IModelTolerance>) => Util.sharesCellRenderer(params, tradeDecimalEnum.amountDecimal),
        hide: true,
        width: 103,
        cellClass: 'text-right',
        tooltipValueGetter: this.TargetAndDifferenceToolTipValueGetter
      },
      <ColDef>{
        headerName: 'Difference',
        field: 'differenceInShares',
        cellRenderer: (params: ICellRendererParams<IModelTolerance>) => Util.sharesCellRenderer(params, tradeDecimalEnum.amountDecimal),
        hide: true,
        width: 103,
        cellClass: 'text-right',
        tooltipValueGetter: this.TargetAndDifferenceToolTipValueGetter,
        cellClassRules: this.toleranceCellClassRules
      },
      <ColDef>{
        headerName: 'Post',
        field: 'postTradeInShares',
        cellRenderer: (params: ICellRendererParams<IModelTolerance>) => Util.sharesCellRenderer(params, tradeDecimalEnum.amountDecimal),
        hide: true,
        width: 103,
        cellClass: 'text-right'
      }
    ];
    // To suppress floating filter button from all columns in the grid
    for (const columnDef of this.columnDefs) {
      columnDef.floatingFilter = false;
    }
  }

  private selectCellValueType(type: number): void {
    this.selectedCellValueType = type;
    this.setColumnsVisible(type);
    if (this.isModelToleranceForExcludedFF) {
      this.setExcludedCashValues(type);
    }
  }

  selectBarChartType(type: number): void {
    this.selectedBarChartType = type;
    if (this.isModelToleranceUpdatesFF) {
      this.onTabChange(this.selectedTab, this.selectedLevelId);
    } else {
      this.onTabChangeLegacy(this.selectedTab, this.selectedLevelId);
    }
  }

  private setColumnsVisible(type: ViewType) {
    let columnsToHide = this.percentageColumns.concat(this.dollarColumns, this.sharesColumns);
    this.gridApi.setColumnsVisible(columnsToHide, false);

    columnsToHide = (type === ViewType.Percent) ? this.percentageColumns : ((type === ViewType.Dollars) ? this.dollarColumns : this.sharesColumns);
    this.gridApi.setColumnsVisible(columnsToHide, true);
    this.gridApi.setColumnWidths([{key: 'name', newWidth: this.selectedCellValueType === 2 ? 108 : 194}]);
  }

  private disposeData() {
    this.dataLoadSubscription?.unsubscribe();
    this.modelTolSetAsideCash = null;
    this.gridRowData = [];
    this.orderSecurities = [];
    this.orderSubClasses = [];
    this.orderSecuritySet = [];
    this.orderClasses = [];
    this.orderCategories = [];
    this.orderSleeves = [];
    this.orderSleeveStrategy = [];
    this.disposeBarGraphChart();
    if (this.isModelToleranceForExcludedFF) {
      this.disposeExcludedCashDetails();
    }
  }

  private setFloatingRowsValue() {
    this.gridApi?.setGridOption('pinnedTopRowData', null);
    const custodialCashIndex = this.gridRowData.findIndex(modelTolerance => ['custodial cash', 'cash']
      .includes(modelTolerance.assetName?.toLowerCase()));

    if(custodialCashIndex >= 0 && this.gridRowData.length > 1) {
      const custodialCash = this.gridRowData.splice(custodialCashIndex, 1);
      this.gridApi?.setGridOption('pinnedTopRowData', custodialCash);
    }
  }

  getPortfolioFlag(portfolioId: number, refreshAnalyzer: boolean): Observable<any> {
    return this._portfolioService.getPortfolioFlag([portfolioId])
      .pipe(tap(data => {
        if (data?.length) {
          const portfolioAnalytic = {} as IPortfolioAnalytics;
          this.needAnalytics = data[0].needAnalytics;
          portfolioAnalytic.failedReason = data[0].failedReason;
          portfolioAnalytic.needAnalytics = data[0].needAnalytics;
          portfolioAnalytic.portfolioId = data[0].portfolioId;
          portfolioAnalytic.editedDate = data[0].analyticsEditedDate;
          this.portfolioAnalytics.push(portfolioAnalytic);
        }
        if (refreshAnalyzer) {
          this.loadModelTolerance(this.modelToleranceData);
        }
      }));
  }

  refreshData(event) {
    this.needAnalytics = event.needAnalytics;
    if (event.portfolioFlag && event.portfolioFlag.length > 0 && this.modelToleranceData?.portfolio) {
      // eslint-disable-next-line eqeqeq
      const portfolio = event.portfolioFlag.find(p => p.portfolioId == this.modelToleranceData.portfolio.id);
      if (portfolio) {
        this.disposeData();
        this.setFloatingRowsValue();
        this.getPortfolioFlag(this.modelToleranceData.portfolio.id, true).subscribe();
      }
    }
  }

  // TODO: "model_tolerance_updates_e2878": remove this function when feature flag is killed.
  exportToExcelLegacy(): void {
    const params = <CsvExportParams>{
      skipFooters: true,
      skipRowGroups: true,
      fileName: 'Model_Tolerance.csv'
    };
    this.gridApi.exportDataAsCsv(params);
  }

  exportToExcel(): void {
    const params = <CsvExportParams>{
      skipFooters: true,
      skipRowGroups: true,
      processCellCallback: cellParams => {
        if (cellParams.column.getColDef().field === 'assetSymbol') {
          return ` ${cellParams.value}`;
        }
        return cellParams.value;
      },
      fileName: 'Model_Tolerance.csv'
    };
    this.gridApi.exportDataAsCsv(params);
  }

  getModelToleranceExcludedCashValues(accountPortfolioId: string, pIdType: string): void {
    if (this.needAnalytics !== AnalyticsStatus.Good) {
      return;
    }

    this._accountService.getModelToleranceExcludedCash(accountPortfolioId, pIdType)
      .subscribe((cashDetails: IExcludedCashDetails) => {
        this.excludedCashDetails = cashDetails;
        this.setExcludedCashValues(this.selectedCellValueType);
      });
  }

  setExcludedCashValues(viewType: number): void {
    if (!this.excludedCashDetails) {
      return;
    }

    if (viewType === ViewType.Percent) {
      const excludedCashActual = Util.calculatePercentage(this.excludedCashDetails.excludedCashActual, this.excludedCashDetails.totalValue);
      const excludedCashTarget = Util.calculatePercentage(this.excludedCashDetails.excludedCashTarget, this.excludedCashDetails.totalValue);

      this.excludedCashActual = new PercentageFormatPipe().transform(excludedCashActual, false);
      this.excludedCashTarget = new PercentageFormatPipe().transform(excludedCashTarget, false);
    } else {
      this.excludedCashActual = new AmountFormatCurrencyPipe().transform(this.excludedCashDetails.excludedCashActual);
      this.excludedCashTarget = new AmountFormatCurrencyPipe().transform(this.excludedCashDetails.excludedCashTarget);
    }
  }

  disposeExcludedCashDetails(): void {
    this.excludedCashActual = RESET_EXCLUDED_CASH_VALUES.VALUE;
    this.excludedCashTarget = RESET_EXCLUDED_CASH_VALUES.VALUE;
    this.excludedCashDetails = null;
  }

  symbolToolTipValueGetter(params: ITooltipParams<IModelTolerance>): string {
    let toolTipValue = params.data.assetName;
    if (params.data.isExcluded) {
      toolTipValue = `${toolTipValue} (${ExcludedSecurity})`;
    }
    return toolTipValue;
  }

  getDataPath(data: IModelTolerance): string[] {
    if (data.isExcluded) {
      return [`${data.hierarchyIds[0]}_${data.isExcluded}`];
    }
    return data.hierarchyIds.map(String);
  }

  getRowId(params: GetRowIdParams<IModelTolerance>): string {
    let id = params.data.securityId.toString();
    if (params.data.hierarchyIds?.length > 1) {
      id = `${params.data.hierarchyIds[0]}-${id}`;
    }
    if (params.data.isExcluded) {
      id = `${params.data.hierarchyIds[0]}-${params.data.isExcluded}`;
    }
    return id;
  }

  TargetAndDifferenceToolTipValueGetter(params: ITooltipParams<IModelTolerance>): string {
    if (params.data.securityRelatedType === SECURITY_RELATED_TYPE.EQUIVALENT) {
      return Messages.MODEL_ANALYZER.EQUIVALENT_ALTERNATE_TOOL_TIP('equivalents');
    } else if (params.data.securityRelatedType === SECURITY_RELATED_TYPE.ALTERNATE) {
      return Messages.MODEL_ANALYZER.EQUIVALENT_ALTERNATE_TOOL_TIP('alternates');
    } else if (params.colDef['field'] === 'differenceInDollar' || params.colDef['field'] === 'targetInDollar') {
      return Util.currencyValueForTooltip(params);
    }
    return params.value;
  }

  createColumnDefForSelectedTabs(): void {
    if (this.isModelToleranceUpdatesFF) {
      if (this.selectedTab === this.SECURITY_TAB) {
        this.addGroupColumnDefs();
        this.createColumnDefsForSecurityTab();
      } else {
        this.createColumnDefs();
      }
      this.gridApi?.setGridOption('columnDefs', this.columnDefs);
    } else {
      this.createColumnDefs();
    }
  }

  addGroupColumnDefs(): void {
    this.gridOptions.suppressContextMenu = true;
    this.gridOptions.groupDefaultExpanded = -1;
    this.gridOptions.autoGroupColumnDef = {
      headerName: 'Name',
      width: 150,
      headerTooltip: 'Name',
      floatingFilter: false,
      field: 'assetSymbol',
      filter: 'agTextColumnFilter',
      cellRenderer: 'agGroupCellRenderer',
      cellRendererParams: {
        innerRenderer: (params: ICellRendererParams<IModelTolerance>) => {
          return params.data.assetSymbol;
        },
        suppressCount: true
      },
      tooltipValueGetter: this.symbolToolTipValueGetter
    };
  }

  private toleranceCellClassRules = {
    'out-of-tolerance-cell': function (params) {
      let outOfTolerance: boolean = false;
      const data = params.node.data;

      if (params.context?.self.showOutOfToleranceInRed) {
        if (params.context.self.selectedTab === 'SECURITY' && data.parentSecurityId) {
          if(data.securityId === data.parentSecurityId) {
            let aggValues = params.context.self.totalsByParentSecurityId?.filter(p => p.parentSecurityId === data.parentSecurityId);
            if (aggValues && aggValues.length === 1) {
              let aggValue = aggValues[0];

              outOfTolerance = (params.context.self.selectedBarChartType === BarChartType.Current)
                ? (aggValue.currentInPercentage > data.upperModelTolerancePercentage || aggValue.currentInPercentage < data.lowerModelTolerancePercentage)
                : params.context.self.useOutOfToleranceFlag ? data.outofTolerance :(aggValue.postTradeInPercentage > data.upperModelTolerancePercentage || aggValue.postTradeInPercentage < data.lowerModelTolerancePercentage);
            }
          }
        }
        else {
          outOfTolerance = (params.context.self.selectedBarChartType === BarChartType.Current) ? (data.currentInPercentage > data.upperModelTolerancePercentage || data.currentInPercentage < data.lowerModelTolerancePercentage) : data.outofTolerance;
        }

        if(params.context.self.showModelToleranceNoModel && data.targetInPercentage === 0  && data.differenceInPercentage === 0) {
          outOfTolerance = false;
        }
      }
      return outOfTolerance;
    }
  };
}
