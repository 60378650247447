<div *ngFor="let batchName of batchesForStackedBar">
  <div class="running-bar">
    <a class="progress-close"><i class="fas fa-thumbtack" aria-hidden="true"></i></a>
    <div class="row">
      <div class="col-md-8">
        <span class="text-xs-center">{{ batchStatus[batchName]?.isMultiBatchRequest ? 'Multiple Batch Optimization' : batchName }}</span>
      </div>
    </div>
    <p-chart type="bar" height="40px" [data]="stackedBarData[batchName]" [options]="stackedBarOptions[batchName]"></p-chart>
  </div>
</div>

<p-dialog [(visible)]="displaySpinner['Initiated']" modal="true" class="ui-dialog-lg">
  <div class="col-md-12 col-sm-12">
    <div class="row" style="height:93px;">
      The batch has been submitted.
    </div>
  </div>
  <p-footer>
    <button type="button" pButton (click)="onHide('Initiated')" icon="pi pi-times" class="p-button-secondary"
      label="Close">
    </button>
  </p-footer>
</p-dialog>

<div class="batch-dialog">
  <ng-template ngFor let-batchName [ngForOf]="batchesForDialog">
    <div>
      <button type="button" *ngIf="hideStatusDialog[batchName]" pButton icon="pi pi-window-maximize"
        (click)="onMaximize(batchName)"></button>
    </div>
    <p-dialog [(visible)]="displaySpinner[batchName]" modal="true" class="ui-dialog-lg" [closable]="false">
      <div class="optimize-button-group">
        <span class="optimize-minimize" (click)="onMinimize(batchName)"><i class="pi pi-minus"></i></span>
        <span class="optimize-close" (click)="onHide(batchName)"><i class="pi pi-times"></i></span>
      </div>

      <div class="col-md-12 col-sm-12">
        <div class="row">
          <table class="table">
            <caption class="hidden-element">Batch Status</caption>
            <thead>
              <tr>
                <th scope="col">Status</th>
                <th scope="col">Comments</th>
                <th scope="col">Ready</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{batchStatus[batchName]?.status}}</td>
                <td>{{batchStatus[batchName]?.comments}}</td>
                <td>{{batchStatus[batchName]?.isReady ? "Yes" : "No"}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <p-footer>
        <i class="fas fa-spinner fa-solid fa-pulse fa-fw pull-left text-white" *ngIf="!isOptimizationComplete[batchName] && !batchStatus[batchName].isError"
          style="font-size:32px;" aria-hidden="true"></i>
        <button type="button" pButton *ngIf="isOptimizationComplete[batchName] && !batchStatus[batchName].isError"
          (click)="navigateToAstroBatches(batchName)" class="p-button-secondary" label="View Batch"></button>
        <button type="button" pButton (click)="onHide(batchName)" icon="pi pi-times" class="p-button-secondary"
          label="Close"></button>
      </p-footer>
    </p-dialog>
  </ng-template>
</div>
