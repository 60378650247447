import { InjectionToken } from '@angular/core';

export type FeatureFlagGroup = {
  group: string;
  flags: Array<FeatureFlagGroup | string>;
};

export type FeatureFlagRegistry = (FeatureFlagGroup | string)[];

const FEATURE_FLAG_TOKEN: string = 'FEATURE_FLAGS';

/**
 * Injection token for providing the feature flag registry.
 */
export const FEATURE_FLAGS = new InjectionToken<FeatureFlagRegistry>(FEATURE_FLAG_TOKEN, {
  providedIn: 'root',
  factory: () => FeatureFlags
});

/**
 * A list of feature flags that will be resolved at the start of the application
 * (after the user has been authenticated, but before they enter the main application).
 * Switching firms, switching users, or refreshing the page will cause this list to be re-resolved.
 *
 * Grouping the flags is optional and subjective, but highly encouraged.
 */
const FeatureFlags: FeatureFlagRegistry = [
  {
    group: 'Accounts',
    flags: [
      'TEXP_dashboard_saved_views_4360',
      'trading_eclipse_portfolio_status_icon_189133',
      'TEXP_edit_tags_accounts_7459',
      'sleeve_deviation_percent_s248999',
      'security_deviation_s249139',
      'Eclipse_last_position_date_1576',
      'securityset_deviation_s2144',
      'subclass_deviation_s2143',
      'class_deviation_percent_s3146',
      'TEXP_restricted_plans_in_line_edit_4149',
      'category_deviation_percent_s3145',
      'mass_liquidation_f211351',
      'Eclipse_Trading_Account_Grid_Outsourced_INVST_6284',
      'MS_sleeve_transfer_accounts_4823',
      'auto_rebalance_on_sleeve_s1671',
      'TEXP_public_views_firm_action_items_8979',
      'TEXP_set_aside_cash_details_update_9842',
      'TEXP_rep_id_accounts_10111'
    ]
  },
  {
    group: 'Trades',
    flags: ['TEXP_rep_id_orders_10110']
  },
  {
    group: 'Overview',
    flags: ['TEXP_hide_reset_analytics_9763']
  },
  {
    group: 'ManageUsers',
    flags: ['TEXP_rep_number_team_9877']
  },
  {
    group: 'Trade Tools',
    flags: [
      {
        group: 'Pricing',
        flags: [
          'skip_quodd_10037',
        ]
      },
      {
        group: 'Tactical',
        flags: [
          'trading_tactical_rtp_f17146',
          'cash_target_reserve_b8154',
          'reserve_cash_usage_s8538',
          'account_equivalents_distribution_s8973',
          'current_target_not_calculating_b9349',
          'mass_liquidation_f211351',
          'tolerance_indicator_rounding_issue_b8952',
          'tactical_discretionary_field_8820',
          'set_aside_not_showing_b9135',
        ]
      },
    ]
  },
  {
    group: 'TradeEx',
    flags: [
      'Eclipse_Trading_MassEdit_1568',
      'Eclipse_Trading_Quodd_ADV_INVST_6947',
      'Eclipse_Trading_Quodd_ADV_INVST_8113',
    ]
  }
];
